<template>
    <div>
        <!-- <pageHeader></pageHeader> -->
        <div class="app">
            <div class="total">
                <sidebar class="sidebar" :active="1"></sidebar>
                <div class="sideright">
                    <div class="end">
                        <div
                            class="ended"
                            v-for="(item,index) of List"
                            :key="index"
                            :class="{ active: index == isActive }"
                            @click="checkItem(index)"
                        >
                            {{ $i18n.locale == 'zh'?item: en_List[index]}}
                            <div class="end-line" v-if="index == isActive"></div>
                        </div>
                    </div>
                    <div class="num">
                        <template v-if="dataList.length > 0">
                            <div class="product" v-for="(item,index) of dataList" :key="index">
                                <div style="display: flex;align-items: center;">
                                    <div class="pro-sign">{{$i18n.locale == "zh"?'注册':'registered'}}</div>
                                    <div class="pro-num">{{$i18n.locale == "zh"?'产品编号':'Product number'}}：{{ item.product_code }}</div>
                                </div>
                                <div class="pro-time">{{$i18n.locale == "zh"?'注册时间':'Registration time'}}：{{ item.created_at }}</div>
                                <div class="pro-time" v-if="$i18n.locale == 'zh'"
                                >{{ item.is_handle_status == 0 ? '待审核' : item.is_handle_status == 1 ? '审核通过' : '审核不通过' }}</div>
                                <div class="pro-time" v-else
                                >{{ item.is_handle_status == 0 ? 'To audit' : item.is_handle_status == 1 ? 'approved' : 'Audit failed' }}</div>
                            </div>
                        </template>
                        <div v-else class="empty">{{$i18n.locale == "zh"?'列表为空':'The list is empty'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <pageFooter></pageFooter> -->
    </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import sidebar from "../components/sidebar";
export default {
    components: { sidebar, pageFooter, pageHeader },
    data() {
        return {
            List: ["待审核", "审核通过", "审核不通过"],
            en_List: ["To audit", "approved", "Audit failed"],
            isActive: 0,
            dataList: []
        }
    },
    mounted() {
        this.isActive = 0
        this.getList()
    },
    methods: {
        checkItem(index) {
            this.isActive = index
            this.getList()
        },
        getList() {
            this.$axios.get('/user_registered_products?is_handle_status=' + this.isActive).then(res => {
                this.dataList = res.data
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.app {
    padding-top: 20px;
    width: 100%;
    margin: 0 auto;
    background: #f1f1f1 100%;
    padding: 0;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 62px;
}

.total {
    width: 1280px;
    height: auto;
    margin: auto;
    display: flex;
    align-items: flex-start;
}
.end {
    display: flex;
    align-items: center;
    margin-left: 51px;
}
.ended {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    margin-right: 99px;
    margin-top: 23px;
    cursor: pointer;
}
.active {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1f1f1f;
    line-height: 30px;
}
.end-line {
    width: 46px;
    height: 3px;
    background: #1f1f1f;
    margin: auto;
    margin-top: 5px;
}
.sideright {
    margin-left: 25px;
    width: calc(100% - 265px);
    height: auto;
    background: #ffffff;
}
.num {
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
}
.product {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
        border-bottom: 1px solid #cdcdcd;
    }
}
// .product:nth-child(6) {
//     border: none;
// }
.empty{
    width: 100%;
    height: 100px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.pro-sign {
    width: 55px;
    height: 55px;
    background: #1f1f1f;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ffffff;
}
.pro-num {
    width: 250px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    margin-left: 10px;
}
.pro-time {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
}
</style>