import axios from 'axios'
import qs from 'qs' //一个模块 专门转化key=value  {key:value}

// 封装get请求
function sendGetRequest(url, data) {
	return new Promise(function(resolve, reject) {
		axios.get(url, data).then(res => {
			resolve(res)
		}, err => {
			reject(err)
		})
	})
}

// 封装post请求
function sendPostRequest(url, data) {
	// 一个状态为resolve(包裹的是数据)
	// 或者状态为reject(包裹的是错误信息)
	return new Promise(function(resolve, reject) {
		axios.post(url, data).then(res => {
			resolve(res)
		}, err => {
			reject(err)
		})
	})
}

axios.defaults.baseURL = '/api'
export default {
	// 密码登录
	userLogin(data) {
		return sendPostRequest('/oauth/token', qs.stringify(data))
	},
	// 验证码登录
	userLoginup(data) {
		return sendPostRequest('/oauth/mobile_login', qs.stringify(data))
	},
	// 注册
	userRegist(data) {
		return sendPostRequest('/mobile_register', qs.stringify(data))
	},
	// 发送验证码
	usercode(data) {
		return sendPostRequest('/oauth/send_code', qs.stringify(data))
	}

}
