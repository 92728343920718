<template>
  <div class="backgrounds">
    <img src="../assets/image/background.png" alt class="background-imgs" />
    <div class="hards">
      <div class="textwidth">
        <div class="titimg">
          <img src="../assets/logo/title2.png" draggable="false" class="hard-img" />
        </div>
        <div class="hardtext-tit" @click="tologin">
          {{$i18n.locale == "zh"?'已有账号，马上登录':'You have an account, log in now'}}</span> </div>
        <div class="hard-text">
          <div class="hard-tit">
            <div class="text-tit">{{$i18n.locale == "zh"?'注册':'registered'}}</div>
          </div>
          <!-- 验证码登录 -->
          <div class="phone">
            <div class="phone-s">
              <div>+86<i class="el-icon-arrow-down"></i></div>
              <input type="text" :placeholder="$i18n.locale == 'zh'?'请输入手机号':'Please enter your phone number'"
                class="inp" v-model="mobile" autocomplete="new-password" />
            </div>
            <div class="phone-s">
              <span style="width:40%;">{{$i18n.locale == "zh"?'验证码':'verification code'}}</span>
              <input type="password" :placeholder="$i18n.locale == 'zh'?'请输入验证码':'Please enter code'" class="inp"
                v-model="code" autocomplete="new-password" />
              <div class="but" @click="obtain">{{$i18n.locale == "zh"?'获取验证码':'Obtaining verification code'}}</div>
            </div>
            <div class="phone-s">
              <span style="width:40%">{{$i18n.locale == "zh"?'密码':'Password'}}</span>
              <input type="password" :placeholder="$i18n.locale == 'zh'?'请输入密码':'Please enter your password'"
                class="inp" v-model="password" />
            </div>
            <div class="phone-s">
              <span style="width:40%">{{$i18n.locale == "zh"?'确认密码':'confirm password'}}</span>
              <input type="password" :placeholder="$i18n.locale == 'zh'?'请输入密码':'Please enter your password'"
                class="inp" v-model="password_confirmation" />
            </div>
            <div calss="readed">
              <el-checkbox v-model="checked" class="checkbox"></el-checkbox>
              {{$i18n.locale == "zh"?'我已阅读并接受《隐私政策》':'I have read and accept the Privacy Policy'}}
            </div>
            <div class="login-but" @click="clickRegist">{{$i18n.locale == "zh"?'完成注册':'complete the registration'}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="power">
			版权所有©2021伊斯曼音乐公司隐私政策条款与条件</div> -->
    <!-- <pageFooter></pageFooter> -->
  </div>
</template>
<script>
import api from '../api/index.js'
export default {
  data() {
    return {
      checked: false,
      mobile: '',
      code: '',
      password: '',
      usage: '',
      password_confirmation: ''
    };
  },
  methods: {
    tologin() {
      this.$router.push({
        path: "./sign"
      })
    },
    clickRegist() {
      if (this.mobile == "" || this.code == "" || this.password == "" || this.password_confirmation ==
        "") {
        if (this.$i18n.locale == 'zh') {
          this.$message.warning('请检查参数')
        } else {
          this.$message.warning('Please check the parameters')
        }

        return;
      } else if (this.checked == false) {
        if (this.$i18n.locale == 'zh') {
          this.$message.warning('请勾选')
        } else {
          this.$message.warning('Please check ')
        }

        return;
      }
      api.userRegist({
        mobile: this.mobile,
        code: this.code,
        password: this.password,
        password_confirmation: this.password_confirmation
      }).then((res) => {
        if (res.code !== 200) {
          this.$message.error(res.message)
        } else if (res.code == 200) {

          this.$message.success(res.message)
          this.$router.push({
            path: "./sign"
          })
        }
      }).catch(err => {

      })
    },
    // 发送验证码
    obtain() {
      console.log(api)
      api.usercode({
        mobile: this.mobile,
        usage: 'register',
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
};
</script>
<style scoped>
.body {
  margin: 0;
  padding: 0;
}

.hard-img {
  width: 100%;
  height: 100%;
  user-select: none;
}
.readed {
  font-size: 14px;
}
.backgrounds {
  width: 100%;
  height: 100%;
  position: absolute;
}

.background-imgs {
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  position: absolute;
}

.hards {
  width: 1280px;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.titimg {
  width: 225px;
  height: 38px;

  margin: auto;
  margin-bottom: 60px;
}

.textwidth {
  width: 480px;
  height: auto;
}

.hardtext-tit {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 220px;
  margin-bottom: 30px;
  cursor: pointer;
  user-select: none;
}

.hard-text {
  width: 480px;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 30px;
}

.hard-tit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: default;
}

.text-tit {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  margin: 30px 0;
}

.end-line {
  width: 46px;
  height: 3px;
  background: #1f1f1f;
  margin: auto;
  margin-top: 5px;
}

.active1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}

.phone {
  margin-left: 33px;
  margin-right: 33px;
}

.phone-s {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.inp {
  width: 56%;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  margin-left: 20px;
}

.readed {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.login-but {
  width: 310px;
  height: 48px;
  background: #1f1f1f;
  border-radius: 24px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fffcfc;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 50px;
  cursor: pointer;
  user-select: none;
}

.but {
  background: #1f1f1f;
  width: 108px;
  height: 32px;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 2px;
}

.power {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  left: 50%;
  bottom: 39px;
  transform: translate(-50%);
}
</style>
