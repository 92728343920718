<template>
  <div>
    <div class="arttitle">
      <div class="titletext">{{$i18n.locale == "zh"?'产品注册':'Registration of products'}}</div>
      <div class="banner-box">
        <el-carousel
          trigger="click"
          autoplay="true"
          height="450px"
          :interval="3000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <div class="small" @click="$router.push(`/activity?dutyId=${item.id}`)"> -->
            <div class="small">
              <img :src="item.cover" alt class="swiperimg" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="hard">
      <div class="put">
        <div class="put1">
          <div class="input-left">{{$i18n.locale == "zh"?'产品编号':'Product number'}}：</div>
          <input type="text" v-model="sendData.product_code"
            :placeholder="$i18n.locale == 'zh'?'请输入产品编号':'Please enter the product number'" class="product-put" />
        </div>
        <div class="put1 ">
          <div class="nv input-left">{{$i18n.locale == "zh"?'性别':'gender'}}：</div>
          <el-select v-model="sendData.buy_product_gender"
            :placeholder="$i18n.locale == 'zh'?'请选择您的性别':'Please select your gender'" style="color:#999"
            class="select-gender">
            <el-option v-for="(item, index) in options" :key="index"
              :label="$i18n.locale == 'zh'?item.label:en_options[index].label" :value="item.value"></el-option>
          </el-select>
        </div>

        <div class="put1">
          <div class="input-left">{{$i18n.locale == "zh"?'年龄':'age'}}：</div>
          <input v-model="sendData.buy_product_age"
            :placeholder="$i18n.locale == 'zh'?'请输入您的年龄':'Please enter your age'" class="product-put"
            onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
        </div>
        <div class="put1">
          <div class="input-left">{{$i18n.locale == "zh"?'您的姓名':'your name'}}：</div>
          <input type="text" v-model="sendData.buy_product_name"
            :placeholder="$i18n.locale == 'zh'?'请输入您的姓名':'Please enter your name'" class="product-put" />
        </div>
        <div class="put1">
          <div class="input-left">{{$i18n.locale == "zh"?'联系电话':'phone number'}}：</div>
          <input v-model="sendData.buy_product_mobile"
            :placeholder="$i18n.locale == 'zh'?'请输入联系电话':'Please enter your contact number'" class="product-put"
            onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
        </div>
        <div class="put1">
          <div class="input-left">{{$i18n.locale == "zh"?'地址':'address'}}：</div>
          <input type="text" v-model="sendData.buy_product_address"
            :placeholder="$i18n.locale == 'zh'?'请输入您的常用地址':'Please enter your usual address'" class="product-put" />
        </div>

        <div class="el">
          <div class="el-left">{{$i18n.locale == "zh"?'购买渠道':'Purchase channels'}}：</div>
          <el-radio v-model="sendData.buy_product_channel" label="0">{{$i18n.locale == "zh"?'老师':' teacher'}}</el-radio>
          <el-radio v-model="sendData.buy_product_channel" label="1">{{$i18n.locale == "zh"?'经销商':'dealers'}}</el-radio>
        </div>
        <div class="teacher" v-if="$i18n.locale == 'zh'">
          <div class="input-left">{{sendData.buy_product_channel==0?'老师姓名':'经销商名'}}：</div>
          <input type="text" v-model="sendData.teacher_name"
            :placeholder="sendData.buy_product_channel==0?'请输入老师姓名':'请输入经销商名'" class="product-putname" />
        </div>
        <div class="teacher" v-else>
          <div class="input-left">{{sendData.buy_product_channel==0?'Teacher name':'Dealer name'}}：</div>
          <input type="text" v-model="sendData.teacher_name"
            :placeholder="sendData.buy_product_channel==0?'Please enter your teacher name':'Please enter the dealer name'"
            class="product-putname" />
        </div>
        <div class="teacher" v-show="sendData.buy_product_channel==0">
          <div class="input-left">{{$i18n.locale == "zh"?'联系电话':'phone number'}}：</div>
          <input v-model="sendData.teacher_tel"
            :placeholder="$i18n.locale == 'zh'?'请输入联系电话':'Please enter your contact number'" class="product-putname"
            onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
            onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
        </div>
      </div>
      <div class="elbottom" @click="getLogIn">{{$i18n.locale == "zh"?'注册':'registered'}}</div>
    </div>
  </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";

export default {
  components: {
    pageFooter,
    pageHeader
  },
  data() {
    return {

      radio: "1",
      input1: "",
      options: [{
        value: "1",
        label: "男"
      },
      {
        value: "0",
        label: "女"
      }
      ],
      en_options: [{
        value: "1",
        label: "man"
      },
      {
        value: "0",
        label: "woman"
      }
      ],
      value: "",
      sendData: {
        product_code: '',
        buy_product_name: '',
        buy_product_gender: '',
        buy_product_age: '',
        buy_product_mobile: '',
        buy_product_address: '',
        buy_product_channel: '0',
        teacher_name: '',
        teacher_tel: ''
      },
      token: localStorage.getItem('token'),
      bannerList:[],
    }
  },
  mounted() {
    this.getBanner();
  },
  methods: {
    getLogIn() {
      console.log(this.token)
      if (!this.token) {
        this.$confirm(this.$i18n.locale == 'zh' ? '登录使用更多功能' : 'Log in to use more features')
          .then(_ => {
            this.$router.push("/sign");
          }).catch(_ => { });
      } else {
        this.toregister()
      }
    },
    toregister() {
      console.log(this.sendData);
      if (this.sendData.product_code &&
        this.sendData.buy_product_name &&
        this.sendData.buy_product_gender != "" &&
        this.sendData.buy_product_age &&
        this.sendData.buy_product_mobile &&
        this.sendData.buy_product_address &&
        this.sendData.teacher_name) {

        this.$axios.post('/add_products_register', this.sendData)
          .then(res => {
            this.$message.success({
              message: this.$i18n.locale == 'zh' ? '产品注册已提交' : 'Product registration has been submitted'
            });

            this.sendData = {
              product_code: '',
              buy_product_name: '',
              buy_product_gender: '',
              buy_product_age: '',
              buy_product_mobile: '',
              buy_product_address: '',
              buy_product_channel: '',
              teacher_name: '',
              teacher_tel: ''
            }

          }).catch(err => {
            this.$message.success({
              message: err.msg
            });
          })
      } else {
        this.$message.error({ message: this.$i18n.locale == 'zh' ? '请填写全部内容' : 'Please fill in all the information' });
      }
    },
    getBanner() {
        this.$axios.get("/banner-list").then((res) => {
          this.bannerList = res.data["user_registered_products"];
        });
      },
  }
};
</script>
<style lang="scss" scoped>
@import url("../style/el.css");

.titletext {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
::v-deep .el-input__inner,
.product-put,
.product-putname,
.productext {
  height: 48px !important;
  color: #222 !important;
  border-color: #999 !important;
  outline-color: #6a6a6a;
  &::placeholder {
    color: #999;
  }
}
::v-deep .el-select-dropdown__item.selected {
  color: #000;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/* .nv {
		width: 80px;
		margin-top:20px;
	} */

.arttitle {
  width: 100%;
  height: 450px;
  position: relative;
  // background-image: url("../assets/image/background.png");
  // background-position: center center;
  margin-bottom: 50px;
}

.hard {
  width: 1300px;
  height: auto;
  margin: auto;
}

.put {
  width: 960px;
  height: auto;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1f1f1f;
  text-align: right;
  margin: auto;
  position: relative;
}
.input-left {
  width: 190px;
  flex-shrink: 0;
  position: relative;
  top: 15px;
}
.product-put {
  flex: 1;
  /* width: 700px; */
  height: 48px;
  background: #ffffff;
  border: 1px solid #6a6a6a;
  border-radius: 4px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  padding-left: 10px;
  margin-top: 32px;
}
.put1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el {
  margin-top: 30px;
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1f1f1f;
  line-height: 30px;
  .el-left {
    width: 190px;
  }
  &::v-deep .el-radio__label {
    font-size: 18px;
  }
}

.el-radio {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
}

.product-putname {
  // width: 660px;
  flex: 1;
  // height: 56px;
  background: #ffffff;
  border: 1px solid #1f1f1f;
  border-radius: 4px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  padding-left: 10px;
}

.teacher {
  // width: 770px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1f1f1f;
  margin: 30px auto;
  padding-left: 90px;
  display: flex;
  align-items: center;
  .input-left {
    position: relative;
    top: 0;
    text-align: right;
    font-size: 18px;
    flex-shrink: 0;
  }
}

.elbottom {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 44px;
  border: 1px solid #1f1f1f;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1f1f1f;
  margin-top: 60px;
  margin-bottom: 60px;
  cursor: pointer;

  &:active {
    opacity: 0.9;
  }
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #1f1f1f;
}
/* 选中后小圆点的颜色 */
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #1f1f1f;
  border-color: #1f1f1f;
}

.banner-box {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
        min-width: 1280px;
      
        .swipertext {
          width: 1280px;
          height: 100%;
          margin: 0 auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 20;
          display: flex;
          align-items: center;
        }
      
        .swipertexts {
        }
        .small {
          height: 100%;
          width: 100%;
          img{
            width: 100%;
            height: 450px;
            object-fit: cover;
          }
        }
      }
</style>
