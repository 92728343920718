<template>
  <div>
    <div class="block">
      <span class="demonstration"></span>
      <!-- <el-carousel height="800px">
				<el-carousel-item v-for="item in 3" :key="item" type>
					<h3 class="small">
						<img src="../assets/image/background.png" alt class="swiperimg" />
					</h3>
				</el-carousel-item>
			</el-carousel> -->
      <!-- <img src="../assets/image/background.png" alt class="swiperimg" style="width: 100%;" /> -->
      <div class="banner-box">
        <el-carousel
          trigger="click"
          autoplay="true"
          height="450px"
          :interval="3000"
          arrow="always"
          class="el-carousel__indicators--horizontal"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <div class="small" @click="$router.push(`/activity?dutyId=${item.id}`)"> -->
            <div class="small">
              <img :src="item.cover" alt class="swiperimg" />
            </div>
          </el-carousel-item>
        </el-carousel>
        
      </div>
      <div class="swipernext">
        <div class="aervicesou">
          <input
            type="text"
            placeholder="搜索"
            class="sou"
            v-model="keywords"
            @keyup.enter="getList"
          /><i class="el-icon-search" @click="getList"></i>
        </div>
        <div class="service-for" v-if="list.length">
          <div v-for="(i, index) of list" :key="index" class="service-index">
            <div class="serviceimg-wrapper">
              <img
                :src="i.picture"
                alt
                class="serviceimg"
                @click="toGoodsDetails(i.id)"
              />
            </div>
            <div class="service-right">
              <div class="serviceone" @click="toGoodsDetails(i.id)">
                {{ $i18n.locale == "zh" ? i.goods_name : i.en_goods_name }}
              </div>
              <div
                class="servicetwo"
                @click="toGoodsDetails(i.id)"
                v-html="
                  $i18n.locale == 'zh'
                    ? i.short_description
                    : i.en_short_description
                "
              ></div>
              <div class="servicethree" @click="toGoodsDetails(i.id)">
                {{ $i18n.locale == "zh" ? "了解更多" : "learn more" }}
              </div>
            </div>
          </div>
        </div>
        <div v-else style="text-align: center; padding: 50px 0">
          {{ $i18n.locale == "zh" ? "暂无内容" : "No content" }}
        </div>
        <div class="page-box">
          <el-pagination
            hide-on-single-page="false"
            :total="list.length"
            :background="true"
            layout="prev, pager, next"
            calss="pagin"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
export default {
  components: {
    pageFooter,
    pageHeader,
  },
  data() {
    return {
      list: [],
      keywords: "",
      bannerList: [],
    };
  },
  watch: {
    $route: {
      handler(nv, ov) {
        this.getList();
      },
    },
    deep: true,
  },
  methods: {
    toGoodsDetails(id) {
      this.$router.push(`Detail?id=${id}`);
    },
    getList() {
      let goodsId = this.$route.query.id;
      console.log(goodsId);
      this.$axios
        .get(`/goods?keyword=${this.keywords}&cate_id=${goodsId}`)
        .then((res) => {
          this.list = res.data;
        });
    },

    getBanner() {
      this.$axios.get("/banner-list").then((res) => {
        this.bannerList = res.data["product_service"];
      });
    },
  },
  mounted() {
    this.getList();
    this.getBanner();
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-carousel__button {
//   border: 1px solid gray;
//   width: 14px;
//   height: 14px;
//   border-radius: 50%;
//   background: #000;
// }

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.swiperimg {
  width: 100%;
  object-fit: cover;
  height: 450px;
}

.swipernext {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-icon-search {
  font-size: 20px;
  cursor: pointer;
}

.sou {
  flex: 1;
  height: 100%;
  background: #ffffff;
  border: none;
  outline: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1f1f1f;
}

.aervicesou {
  margin: 30px auto 0 auto;
  padding: 0 30px;
  width: 800px;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border: 1px solid #1f1f1f;
  border-radius: 4px;
}

.activimg {
  width: 100%;
  height: 100%;
}

.bottomun {
  margin-top: 150px;
}
.serviceimg-wrapper {
  width: 300px;
  height: 300px;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.serviceimg {
  max-width: 300px;
  max-height: 300px;
  background: #ffffff;

  cursor: pointer;
  border-radius: 4px;
}

.service-for {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.service-index {
  width: calc(50% - 31px);
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.service-right {
  margin-left: 18px;
}

.serviceone {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #1f1f1f;
  line-height: 30px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

.servicetwo {
  height: 60px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #6e6e6e;
  line-height: 30px;
  margin-top: 10px;
  margin-left: 8px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  white-space: pre-line;
}

.servicethree {
  width: 130px;
  height: 32px;
  border: 1px solid #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  margin-left: 8px;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #1f1f1f;
    border-color: #1f1f1f;
    color: #fff;
  }
}

.page-box {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-box {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  min-width: 1280px;

  .swipertext {
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    display: flex;
    align-items: center;
  }

  .swipertexts {
  }
  .small {
    height: 100%;
    width: 100%;
  }
}

</style>
