import Vue from 'vue'
import VueRouter from 'vue-router'
import Sidebar from '../components/sidebar.vue'
// 详情
import Sidetext from '../personal/sidetext.vue'
// 修改密码
import Sidepass from '../personal/sidepass.vue'
// 绑定手机
import Sidepassun from '../personal/sidepassun.vue'
// 积分记录
import Sidevip from '../personal/sidevip.vue'
// 会员专区
import Sideexpert from '../personal/sideexpert.vue'
// 我报名的活动
import Sideactivity from '../personal/sideactivity.vue'
// 申请的售后服务
import Sideapply from '../personal/sideapply.vue'
// 申请的售后服务
import Message from '../personal/message.vue'
// 注册产品
import Sideregister from '../personal/sideregister.vue'
// 个人资料
import Sidedata from '../personal/sidedata.vue'
// 登录
import Sign from '../views/sign.vue'
// 忘记密码
import Forgetpassword from '../views/forgetpassword.vue'
// 注册
import Register from '../views/register.vue'
// 社会责任
import Sociology from '../views/Sociology.vue'
// 活动详情
import Activity from '../views/activity.vue'
// 官方视频
import Offic from '../views/offic.vue'
// 视频详情
import Video from '../views/video.vue'
// 影音资料
import Art from '../views/art.vue'
//艺术家＆形象大使
import Artdire from '../views/artdire.vue'
// 经销商查询
import Inquire from '../views/inquire.vue'
// 产品详情
import Detail from '../views/detail.vue'
// 产品注册
import Product from '../views/product.vue'
// 产品服务
import Service from '../views/service.vue'
// 售后
import Saleafter from '../views/saleafter.vue'
// 关于我们
import Aboutus from '../views/aboutus.vue'
// 产品查询
import search from '../views/search.vue'
// 产品注册吉他
import Productguitar from '../views/productGuitar.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: 'home'
	},
	{
		path: '/home',
		name: 'Home',
		component: () => import( /* webpackChunkName: "Home" */ '../views/Home.vue')
	},
	{
		path: '/aboutus',
		name: 'Aboutus',
		component: Aboutus
	},
	{
		path: '/saleafter',
		name: 'Saleafter',
		component: Saleafter
	},
	{
		path: '/service',
		name: 'Service',
		component: Service
	},
	{
		path: '/search',
		name: 'search',
		component: search
	},
	{
		path: '/product',
		name: 'Product',
		component: Product
	},
	{
		path: '/productguitar',
		name: 'Productguitar',
		component: Productguitar
	},
	
	{
		path: '/detail',
		name: 'Detail',
		component: Detail
	},
	// 经销商查询
	{
		path: '/inquire',
		name: 'Inquire',
		component: Inquire
	},
	//艺术家＆形象大使
	{
		path: '/artdire',
		name: 'Artdire',
		component: Artdire
	},
	// 影音资料
	{
		path: '/art',
		name: 'Art',
		component: Art
	},
	// 视频详情
	{
		path: '/video',
		name: 'Video',
		component: Video
	},
	// 官方视频
	{
		path: '/offic',
		name: 'Offic',
		component: Offic
	},
	// 忘记密码
	{
		path: '/forgetpassword',
		name: 'Forgetpassword',
		component: Forgetpassword
	},
	// 活动详情
	{
		path: '/activity',
		name: 'Activity',
		component: Activity
	},
	//社会责任
	{
		path: '/Sociology',
		name: 'Sociology',
		component: Sociology
	},
	// 注册
	{
		path: '/register',
		name: 'Register',
		component: Register
	},
	// 个人中心
	{
		path: '/sideactivity',
		name: 'Sideactivity',
		component: Sideactivity
	},
	{
		path: '/sign',
		name: 'Sign',
		component: Sign
	},
	{
		path: '/sidedata',
		name: 'Sidedata',
		component: Sidedata
	},
	{
		path: '/sideregister',
		name: 'Sideregister',
		component: Sideregister
	},
	{
		path: '/sideapply',
		name: 'Sideaapply',
		component: Sideapply
	},
	{
		path: '/sideexpert',
		name: 'Sideexpert',
		component: Sideexpert
	},
	{
		path: '/sidepass',
		name: 'Sidepass',
		component: Sidepass
	},
	{
		path: '/sidevip',
		name: 'Sidevip',
		component: Sidevip
	},
	{
		path: '/sidepassun',
		name: 'Sidepassun',
		component: Sidepassun
	},
	{
		path: '/sidetext',
		name: 'Sidetext',
		component: Sidetext
	},
	{
		path: '/sidebar',
		name: 'Sidebar',
		component: Sidebar
	},
	{
		path: '/message',
		name: 'Message',
		component: Message
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
