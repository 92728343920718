import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 记录用户是否登录
    isLogin:localStorage.getItem('token') || false,
    // 是否显示tabbar
    showTabbar:true,
    userInfo: null

  },
  mutations: {
    changeIsLogin(state,value){
      state.isLogin=value

    },
    changeShowTabbar(state,value){
      state.showTabbar=value
    },
    setUserInfo(state,val){
        state.userInfo = val
    }
  },
  actions: {
  },
  modules: {
  }
})