<template>
    <div>
        <!-- <pageHeader></pageHeader> -->
        <div class="app">
            <div class="total">
                <sidebar class="sidebar" :active="0"></sidebar>
                <div class="sideright">
                    <div class="data-tit">
                        <div class="data-hard">
                            <div class="tit">{{$i18n.locale == "zh"?'头像':'head portrait'}}：</div>
                            <el-upload
                                class="avatar-uploader"
                                action="/api/upload_after_sale"
                                name="file"
                                :show-file-list="false"
                                :on-success="uploadSuccess"
                            >
                                <div class="data-img">
                                    <img v-if="imageUrl" :src="imageUrl" alt class="imgs" />
                                    <img v-else src="../assets/image/data.png" alt class="imgs" />
                                </div>
                                <div class="data-text">{{$i18n.locale == "zh"?'编辑头像':'Edit avatar'}}</div>
                            </el-upload>
                        </div>
                        <!-- <el-upload
                            class="upload-demo"
                            action="/api/upload_after_sale"
                            :headers="{
                                'authorization': 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjkyZWU5YjEzOTYzZDA2Njk1ZDI4MzY1MzAwNTYyY2Y0MjIwNjVjY2EzMzcxNzAwOTEwYzU5NTUxMWRhNzJlNDgwMWNiZWYxNDg2N2VkZDI2In0.eyJhdWQiOiIxIiwianRpIjoiOTJlZTliMTM5NjNkMDY2OTVkMjgzNjUzMDA1NjJjZjQyMjA2NWNjYTMzNzE3MDA5MTBjNTk1NTExZGE3MmU0ODAxY2JlZjE0ODY3ZWRkMjYiLCJpYXQiOjE2Mzc1NzA2NzMsIm5iZiI6MTYzNzU3MDY3MywiZXhwIjoxNjY5MTA2NjczLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.I0Yigkk_T5KN3Uj-eC4X6yjxWmGUpgnNoMC_hMdI1sDDXV33X-RdBObhvzq-398KUZOfbhrazd0fXDollWEHgX9NsyZWahDl9Gv2b2grlxAn8vXPXDda0Rq6XEYloICJOgbwPl_sIACIkL1TZTUqlqVco6eDF5xZo5JQIuoCLjpiq0e0htt7rZY7Mt5zjlJ3hDj4gyXfuyC5cmXnzaDPcxxuD8xEabqiobdSxC5GkcI_nRw0Y8p4K6xYA555dKDUR9ovrFMpTjcgbgPw-IuObpJP8EX0C8UKjWtKeclfOrIpptNkrwn1iMCW7K9vwlGtjLWJPjaK5szIlRM3a-GCWdjHXEFZ9UjVq8CFY2FnOCNOzPSYFfvfnq0D2ZVSpyfQPoubhseEmRiXtDrT1tP9kO6m9VbVjGN21gBjfjoW_l0sx9ZIqpSd8LQVMnQth6MxtjwxQ5Fa0Aj3YnvG3eK6ZY8TuufTV7xk-NrRp1gfx0XTeeZaYOBHCS7PqDtk870RbBdoWgRQUL187EiFAwy8dAkBD-fLNzrcx5tagL-tcDpIgPQ-TyiIx-pKKJvwd4Qdks7EwR1EKlCoBxhPCD_Q0njWQT6nm9xeqIoLU53KVW2v4jrL22xY8DBcKfeeXkhGdmf_-jarljn9S2sjnIjgySL8RRSBavpvwcbuFKs2XtI'
                            }"
                            name="file"
                            multiple
                            @on-success="uploadSuccess"
                            :limit="1"
                        >
                            <div class="data-text">编辑头像</div>
                        </el-upload>-->
                        <div class="data-name">
                            {{$i18n.locale == "zh"?'昵称':'nickname'}}：
                            <input
                                type="text"
                                :placeholder="$i18n.locale == 'zh'?'请输入':'please input'"
                                class="data-input"
                                v-model="userName"
                            />
                        </div>
                        <div class="data-genter">
                            {{$i18n.locale == "zh"?'性别':'gender'}}：
                            <el-radio  v-model="radio" label="1">{{$i18n.locale == "zh"?'男':'man'}}</el-radio>
                            <el-radio  v-model="radio" label="0">{{$i18n.locale == "zh"?'女':'female'}}</el-radio>
                        </div>
                        <div class="data-genter">
                            {{$i18n.locale == "zh"?'生日':'birthday'}}：
                            <el-select v-model="year" :placeholder="$i18n.locale == 'zh'?'请选择':'Please select'"
                            :no-data-text="$i18n.locale == 'zh'?'请先选择月':'Please select year'" @change="yearChange">
                                <el-option
                                    v-for="(item,index) in optionsYear"
                                    :key="index"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                            </el-select>
                            <div class="year">{{$i18n.locale == "zh"?'年':'year'}}</div>
                            <el-select 
                                v-model="month"
                                :placeholder="$i18n.locale == 'zh'?'请选择':'Please select'"
                                :no-data-text="$i18n.locale == 'zh'?'请先选择月':'Please select month'"
                                @change="monthChange"
                            >
                                <el-option
                                    v-for="(item,index) in optionsMonth"
                                    :key="index"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                            </el-select>
                            <div class="year">{{$i18n.locale == "zh"?'月':'month'}}</div>
                            <el-select v-model="date"  :placeholder="$i18n.locale == 'zh'?'请选择':'Please select'"
                                :no-data-text="$i18n.locale == 'zh'?'请先选择日':'Please select day'">
                                <el-option
                                    v-for="(item,index) in optionsDate"
                                    :key="index"
                                    :label="item"
                                    :value="item"
                                ></el-option>
                            </el-select>
                            <div class="year">{{$i18n.locale == "zh"?'日':'day'}}</div>
                        </div>
                        <div class="preser" @click="save">{{$i18n.locale == "zh"?'保存':'save'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <pageFooter></pageFooter> -->
    </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import sidebar from "../components/sidebar";
export default {
    components: { sidebar, pageFooter, pageHeader },
    data() {
        return {
            radio: "1",
            userName: "", // 昵称
            optionsYear: [],
            optionsMonth: [],
            optionsDate: [],
            startYear: 1960,
            endYear: 2050,
            year: "",
            month: "",
            date: "",
            imageUrl: "",
        };
    },
    created() {
        let date=new Date;
        this.endYear = date.getFullYear()
        this.optionsYear = []
        for (let index = this.startYear; index < this.endYear + 1; index++) {
            this.optionsYear.push(index)
        }
        for (let index = 1; index < 13; index++) {
            if (index > 9) {
                this.optionsMonth.push(index)
            } else {
                this.optionsMonth.push('0' + index)
            }
        }
    },
    mounted() {
        this.getUserInfo()
    },
    methods: {
        // 获取个人信息
        getUserInfo() {
            this.$axios.get('/user').then(res => {
                if (res.code == 200) {
                    let userInfo = res.data
                    this.$store.commit('setUserInfo', res.data)
                    if (userInfo) {
                        this.imageUrl = userInfo.avatar_url
                        this.userName = userInfo.nick_name
                        this.radio = userInfo.gender
                        this.year = userInfo.birthday_day.split("-")[0]
                        this.month = userInfo.birthday_day.split("-")[1]
                        this.date = userInfo.birthday_day.split("-")[2]
                    }
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        //  保存
        save() {
            if(!this.year || !this.month || !this.date || !this.userName){
                return this.$message.warning('请将信息填写完整');
            }
            this.$axios.post('/user_update', {
                avatar_url: this.imageUrl,
                nick_name: this.userName,
                gender: this.radio,
                birthday_day: this.year + '-' + this.month + '-' + this.date
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.message);
                    // this.getUserInfo()
                    history.go(0)
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 头像上传成功 
        uploadSuccess(res, file) {
            this.imageUrl = res.data.url || ''
            if(res.code == 200) {
                this.$message.success(res.message);
            } else {
                this.$message.error(res.message.file[0]);
            }
        },
        yearChange(val) {
            this.month = ''
            this.date = ''
            if (this.month) {
                let day = this.getDaysInOneMonth(val, this.month)
                for (let index = 1; index < day + 1; index++) {
                    if (index > 9) {
                        this.optionsDate.push(index)
                    } else {
                        this.optionsDate.push('0' + index)
                    }
                }
            }
        },
        monthChange(val) {
            this.date = ''
            if (this.year) {
                let day = this.getDaysInOneMonth(this.year, val)
                for (let index = 1; index < day + 1; index++) {
                    if (index > 9) {
                        this.optionsDate.push(index)
                    } else {
                        this.optionsDate.push('0' + index)
                    }
                }
            }
        },
        getDaysInOneMonth(year, month) {
            month = parseInt(month, 10);
            var day = new Date(year, month, 0);
            return day.getDate();
        }
    }
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner, .product-put, .productext, .data-input {
		color: #222!important;
	}
.app {
    padding-top: 20px;
    width: 100%;
    margin: 0 auto;
    background: #f1f1f1 100%;
    padding: 0;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 62px;
}
.tit{
    font-size: 18px;
}
.total {
    width: 1280px;
    height: auto;
    margin: auto;
    display: flex;
    align-items: flex-start;
}

.sideright {
    margin-left: 25px;
    width: calc(100% - 265px);
    height: auto;
    background: #ffffff;
    /* padding-bottom: 50px; */
    padding-right: 50px;
    padding-bottom: 125px;
    box-sizing: border-box;
}
.data-tit {
    margin-left: 29px;
    margin-top: 30px;
}
.data-hard {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    display: flex;
    align-items: center;
}
// ::v-deep .el-upload {
//     display: flex;
//     align-items: center;
// }
.data-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.data-text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    // margin-left: 20px;
}
.imgs {
    width: 80%;
    height: 80%;
    border-radius: 50%;
}
.data-name {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
}
.data-input {
    width: 359px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #cdcdcd;
    margin-top: 31px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    padding-left: 10px;
}
.data-genter {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    margin-top: 30px;
    display: flex;
    align-items: center;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #1F1F1F ;
  font-size: 18px;
}
/* 选中后小圆点的颜色 */
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #1F1F1F ;
  border-color: #1F1F1F ;
}
.el-select {
    width: 200px;
    ::v-deep .el-input__inner {
        width: 200px !important;
        height: 40px !important;
        margin-top: 0;
    }
    ::v-deep .el-input__suffix {
        top: 0 !important;
    }
}
.preser {
    width: 180px;
    height: 44px;
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-left: 58px;
}
.year {
    margin-left: 20px;
    margin-right: 20px;
}
</style>