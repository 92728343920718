<template>
    <div class="text">
        <div
            v-for="(item,index) of List"
            :key="index"
            :class="['side-text', { 'active': active == index }]"
            @click="to(item.url)"
        >{{$i18n.locale == "zh"?item.name:item.en_name}}
        <span class="dot" v-if="unreadNumber&&index===7">{{unreadNumber}}</span>
    </div>
    </div>
</template>
<script>
export default {
    props: ['active'],
    data() {
        return {
            List: [{ name: '个人资料', en_name: 'personal data', url: '/sidedata' },
            { name: '我注册的产品', en_name: 'I registered the product', url: '/sideregister' },
            { name: '我申请的售后服务', en_name: 'I apply for after-sale service', url: '/sideapply' },
            { name: '我报名的活动', en_name: 'I sign up for events', url: '/sideactivity' },
            { name: '会员专区', en_name: 'members area', url: '/sideexpert' },
            { name: '绑定手机', en_name: 'Binding mobile phone', url: '/sidepassun' },
            { name: '修改密码', en_name: 'change password', url: '/sidepass' },
            { name: '站内信', en_name: 'message', url: '/message' }],
            unreadNumber: 0
        }
    },
    mounted() {
        console.log(this.$route)
        this.getMessageNumber()
        if(this.$route.path==='/message'){
            this.readAllMessage()

        }
    },
    methods: {
        to(url) {
            this.$router.push(url)
        },
        getMessageNumber() {
        this.$axios.get('/notifications/unread').then(res => {
            this.unreadNumber=res.data?.count
            console.log(res.data)
        })
    },
    readAllMessage(){
        this.$axios.get('/notifications/show_all').then(res => {
            this.unreadNumber=0
        })
    }
    },

}
</script>
<style  scoped>
.text {
    width: 240px;
    background: #ffffff;
    padding-top: 23px;
    padding-left: 19px;
    padding-bottom: 24px;
    box-sizing: border-box;
}
.side-text {
    margin-bottom: 47px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    cursor: pointer;
    position: relative;
}
.dot{
    position: absolute;
    left: 50px;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: red;
    color: #fff;
    border-radius:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;

}
.active {
    font-weight: bold;
}
.side-text:nth-last-child(1) {
    margin-bottom: 0;
}
</style>