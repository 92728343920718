<template>
  <div>
    <div class="block">
      <span class="demonstration"></span>
      <div class="banner-box">
        <el-carousel trigger="click" autoplay="true" height="450px" :interval="3000" arrow="always">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <div class="small" @click="$router.push(`/activity?dutyId=${item.id}`)"> -->
            <div class="small">
              <img :src="item.cover" alt class="swiperimg" />
            </div>
            <!-- <div class="swipertext">
							<div class="swipertexts">
								<div class="text-tit">{{item.name}}</div>
								<div class="texts">{{item.short_title}}</div>
								<div class="text-list">{{item.duty_category_name}} I {{item.published_at}}</div>
							</div>
						</div> -->
          </el-carousel-item>
        </el-carousel>

      </div>
      <div class="list-content">
        <div class="list-content__label-box">
          <div v-for="(item,index) in categories" :key="index"
            :class="['list-content__label-box__label', {'cur': type_index == index}]" @click="typeChange(item,index)">
            {{$i18n.locale == 'zh'?item.name:item.en_name}}
          </div>
        </div>
      </div>
      <div class="swipernext">
        <div class="side-activity" v-if="sociologyData.length">
          <div class="activity" v-for="(i, index) of sociologyData" :key="index"
            @click="$router.push(`/activity?dutyId=${i.id}`)">
            <div class="img">
              <img :src="i.duty_cover" alt class="activimg" />
            </div>
            <div class="bottomtext">
              <div class="img-bottom">{{$i18n.locale == 'zh'?i.name:i.en_name}}</div>
              <div class="img-bottom-short">{{$i18n.locale == 'zh'?i.short_title:i.en_short_title}}</div>
              <div class="img-list">{{$i18n.locale == 'zh'?i.duty_category.name:i.duty_category.en_name}} I
                {{i.published_at}}</div>
            </div>
          </div>
        </div>
        <div v-else style="text-align:center;padding:50px 0;font-size">{{$i18n.locale == "zh"?'暂无内容':'No content'}}
        </div>
        <el-pagination background layout="prev, pager, next" hide-on-single-page="false" :total="sociologyData.length"
          calss="pagin"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sociologyData: [],
      bannerData: [],
      categories: [],
      type_index: 0,
      type_id: '',
      bannerList: [],
    }
  },
  methods: {
    getData() {
      this.$axios.get('/duty?duty_category_id=' + this.type_id)
        .then(res => {
          this.sociologyData = res.data
        })
    },
    getBanner() {
      this.$axios.get('/banner-list')
        .then(res => {
          this.bannerList = res.data['index_duty']
        })
    },
    typeChange(item, index) {
      this.type_index = index
      this.type_id = item.id || ''
      this.getData()
    },
    getTypes() {
      this.$axios.get('/get_duty_categories').then(res => {
        if (res.code == 200) {
          this.categories = res.data
          this.categories.unshift({ name: '全部', en_name: 'All' })
          this.type_id = res.data[0].id || ''
          this.getData()
        }
      })
    },
  },
  mounted() {
    this.getData()
    this.getBanner()
    this.getTypes()

  }
}
</script>
<style lang="scss" scoped>
.banner-box {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  min-width: 1280px;
  .swipertext {
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    display: flex;
    align-items: center;
  }

  .swipertexts {
  }
  .small {
    height: 100%;
    width: 100%;
  }
}

// ::v-deep .el-carousel__button {
// 	border: 1px solid gray;
// 	width: 14px;
// 	height: 14px;
// 	border-radius: 50%;
// }
.list-content {
  padding: 0 30rpx;

  &__label-box {
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &__label {
      flex-shrink: 0;
      width: 140px;
      height: 33px;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 400;
      color: #333;
      margin-right: 20px;

      &.cur {
        background: #000;
        color: #fff;
      }
    }
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 16px;
  line-height: 150px;
  margin: 0;
}

// .el-carousel__item:nth-child(2n) {
// 	background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n + 1) {
// 	background-color: #d3dce6;
// }

.swiperimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swipernext {
  margin: 10px auto 50px auto;
  width: 1440px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text-tit {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.texts {
  width: 615px;
  height: 44px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}

.text-list {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 20px;
}

.side-activity {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.activity {
  width: 450px;
  margin-bottom: 28px;
  cursor: pointer;
  margin-right: 30px;

  &:nth-of-type(4n) {
    margin-right: 0;
  }
}

.img {
  width: 100%;
  height: 280px;
  background: #333;
}

.imgs {
  width: 88px;
  height: 32px;
  background: #1f1f1f;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #ffffff;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomtext {
  width: 100%;
  border: 1px solid;
  background: #1f1f1f;
  padding: 15px;
  box-sizing: border-box;
}

.img-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img-bottom {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;

  display: -webkit-box;
  // height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.img-bottom-short {
  font-size: 16px;
  color: #ffffff;
  padding: 15px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.img-list {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.activimg {
  width: 100%;
  height: 100%;
}

.el-pagination {
}

.bottomun {
  margin-top: 60px;
}
</style>
