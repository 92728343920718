<template>
    <div>
        <!-- <pageHeader></pageHeader> -->
        <div class="app">
            <div class="total">
                <sidebar class="sidebar"></sidebar>
                <div class="sideright">
                    <div class="return" @click="back">&lt;返回</div>
                    <div class="num">
                        <div class="numtit">
                            <div class="nummoney">
                                {{amount}}
                                <img src="../assets/logo/money.png" alt class="numicon" />
                            </div>
                            <div class="ver"></div>
                            <div class="numtext" v-if="$i18n.locale == 'zh'">
                                积分规则：
                                <br />1、进行乐器注册，注册了就会有相应的分值的积分
                                <br />2、参与活动获得相应积分
                                <br />3、提交售后服务获得相应积分
                                <br />
                            </div>
                            <div class="numtext" v-else>
                                Integral rules：
                                <br />1、Instrument registration, registration will have the corresponding point value of the points
                                <br />2、Participate in activities to obtain corresponding points
                                <br />3、Submit after-sales service to obtain corresponding credits
                                <br />
                            </div>
                        </div>
                        <div class="product" v-for="(item,index) of records" :key="index">
                            <!-- <div class="pro-sign">{{$i18n.locale == 'zh'?item.admin_type_name:item.en_admin_type_name}}</div> -->
                            <div class="pro-sign">{{item.admin_type_name}}</div>
                            <div class="pro-num">{{item.admin_type_desc}}</div>
                            <!-- <div class="pro-regist">{{$i18n.locale == 'zh'?item.admin_type_name:item.en_admin_type_name}}</div> -->
                            <div class="pro-regist">{{item.admin_type_name}}</div>
                            <div class="pro-time">{{item.created_at}}</div>
                            <div class="plus">
                                +{{item.amount}}
                                <img src="../assets/logo/money.png" alt class="plusicon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <pageFooter></pageFooter> -->
    </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import sidebar from "../components/sidebar";
export default {
    components: { sidebar, pageFooter, pageHeader },
    data() {
        return {
            records: [],
            amount: 0
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        back(){
            history.go(-1)
        },
        getData(){
            this.$axios.get('/user_integral_records').then(res=>{
                console.log(res);
                this.records = res.data.records
                this.amount = res.data.amount
            })
        }
    },
};
</script>
<style lang="scss" scoped>
.app {
    padding-top: 20px;
    width: 100%;
    margin: 0 auto;
    background: #f1f1f1 100%;
    padding: 0;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 62px;
}

.total {
    width: 1280px;
    height: auto;
    margin: auto;
    display: flex;
    align-items: baseline;
}

.sideright {
    margin-left: 25px;
    width: calc(100% - 265px);
    height: auto;
    background: #ffffff;
}
.return {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    margin-left: 32px;
    margin-top: 20px;
}
.num {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    margin-top: 34px;
}
.numtit {
    width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    background: #f1f1f1;
    display: flex;
    align-items: center;
}
.nummoney {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 36px;
    margin-left: 50px;
}
.numicon {
    width: 34px;
    height: 34px;
}
.ver {
    width: 2px;
    height: 60px;
    background: #cccccc;
    margin-left: 50px;
}
.numtext {
    margin-left: 50px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    color: #333333;
    line-height: 22px;
}
.product {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cdcdcd;
    overflow: hidden;
}

.pro-sign {
    width: 64px;
    height: 64px;
    background: #1f1f1f;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
}
.pro-num {
    font-size: 18px;
    width: 35%;
    flex-shrink: 1;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    margin-left: 20px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.pro-regist {
    width: 100px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
}
.pro-time {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
}
.plus {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    width: 100px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1f1f1f;
    line-height: 30px;
}
.plusicon {
    width: 21px;
    height: 21px;
    background: #ffffff;
}
</style>