<template>
  <div>
    <!-- <pageHeader></pageHeader> -->
    <div class="app">
      <div class="total">
        <sidebar class="sidebar" :active="4"></sidebar>
        <div class="sideright">
          <div class="righttit">
            <div class="icon">
              <el-select size="small" v-model="value1" :placeholder="$i18n.locale == 'zh'?'请选择':'Please select'"
                @change="activityChange">
                <el-option v-for="(item,i) of activity_categories" :key="i"
                  :label="$i18n.locale == 'zh'?item.name:item.en_name" :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="icon">
              <el-select size="small" v-model="value2" :placeholder="$i18n.locale == 'zh'?'状态':'state'"
                @change="statusChange">
                <el-option :label="$i18n.locale == 'zh'?'已发起':'launched'" :value="0"></el-option>
                <el-option :label="$i18n.locale == 'zh'?'进行中':'underway'" :value="1"></el-option>
                <el-option :label="$i18n.locale == 'zh'?'已结束':'finished'" :value="2"></el-option>
              </el-select>
            </div>
            <div class="searchbut" @click="getList">{{$i18n.locale == "zh"?'搜索':'search'}}</div>
            <div class="recordbut" @click="to('/sidevip')">{{$i18n.locale == "zh"?'积分记录':'Integral record'}}</div>
          </div>
          <div class="side-activity">
            <div class="activity" v-for="(item,index) of list" :key="index"
              @click="to('/sidetext?activity_category_id=' + item.id)">
              <div class="img" :style="{ 'background': 'url(' + item.activity_cover + ') no-repeat center' }">
                <div class="imgs">{{ $i18n.locale == "zh"?item.activity_category.name:item.activity_category.en_name }}
                </div>
              </div>
              <div class="bottomtext">
                <div class="img-bottom">{{ $i18n.locale == "zh"?item.name: item.en_name}}</div>
                <div class="img-list">{{$i18n.locale == "zh"?'活动时间':'activity time'}}：{{ item.activity_start_time }} -
                  {{ item.activity_end_time }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <pageFooter></pageFooter> -->
  </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import sidebar from "../components/sidebar";
export default {
  components: { sidebar, pageFooter, pageHeader },
  data() {
    return {
      value1: '',
      value2: '',
      list: [],
      activity_categories: [] // 活动
    }
  },
  mounted() {
    this.getCategory()
  },
  filters: {
    statusName: (val) => {
      console.log(this);
      // console.log(val);
      // this.activity_categories.forEach(item=>{
      //     if(val.id == item.id){
      //         return item.name
      //     }
      // })
    }
  },
  methods: {
    to(url) {
      this.$router.push(url)
    },
    activityChange(val) {
      this.value1 = val
    },
    statusChange(val) {
      this.value2 = val
    },
    // 获取列表
    getList() {
      this.$axios.get(`/activity?activity_category_id=${this.value1}&status=${this.value2}`).then(res => {
        if (res.code == 200) {
          this.list = res.data
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 活动分类
    getCategory() {
      this.$axios.get('/get_activity_categories').then(res => {
        this.activity_categories = res.data
        let obj = {
          id: '',
          name: '全部活动',
          en_name: 'all activity'
        }
        this.activity_categories.unshift(obj)
        this.getList()
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.app {
  padding-top: 20px;
  width: 100%;
  margin: 0 auto;
  background: #f1f1f1 100%;
  padding: 0;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 62px;
}

.total {
  width: 1280px;
  height: auto;
  margin: auto;
  display: flex;
  align-items: flex-start;
}

.sideright {
  margin-left: 25px;
  width: calc(100% - 265px);
  height: auto;
  background: #ffffff;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.righttit {
  height: 80px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}
.rightinput {
  width: 243px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #1f1f1f;
  border-radius: 4px;
  margin-top: 30px;
  margin-left: 30px;
  margin-bottom: 30px;
  padding-left: 17px;
  outline: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}
.searchbut {
  width: 88px;
  height: 40px;
  background: #1f1f1f;
  border: 1px solid #1f1f1f;
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  cursor: pointer;
}
.recordbut {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #1f1f1f;
  border-radius: 4px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1f1f1f;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 30px;
}
.icon {
  position: relative;
  .el-select {
    // .el-input {
    //     width: 200px !important;
    //     display: flex;
    //     align-items: center;
    // }
    margin-right: 20px;
    ::v-deep .el-input__inner {
      width: 260px !important;
      height: 40px !important;
      margin-top: 0;
    }
    ::v-deep .el-input__suffix {
      top: 0 !important;
    }
  }
}
.puticon {
  width: 16px;
  height: 8px;
  position: absolute;
  right: 20px;
  top: 55px;
}
.side-activity {
  display: flex;
  flex-wrap: wrap;
  margin-left: 30px;
  margin-right: 30px;
}
.activity {
  width: calc(33.33% - 20px);
  margin-bottom: 28px;
  margin-right: 20px;
  background-size: 100%;
}
.img {
  width: 100%;
  height: 200px;
  background: #333;
  background-size: 100% 100% !important;
}
.imgs {
  // width: 88px;
  padding: 0 8px;
  height: 32px;
  background: #1f1f1f;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #ffffff;
  line-height: 32px;
  display: inline-block;
}
.bottomtext {
  width: 100%;
  border: 1px solid;
  background: #1f1f1f;
  padding: 15px 10px;
  box-sizing: border-box;
}
.img-bottom {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.img-list {
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #ffffff;
  margin-top: 10px;
}
</style>
