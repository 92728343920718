<template>
    <div>
        <!-- <pageHeader></pageHeader> -->
        <div class="app">
            <div class="total">
                <sidebar class="sidebar" :active="7"></sidebar>
                <div class="sideright">
                    <div class="message-list" v-if="list.length">
                        <block v-for="(item,index) in list" :key="index">
                            <div class="message-item" @click="showDetail(item)">
                                <div class="message-time">发送时间{{item.created_at}}</div>
                                <div class="message-title">{{item.data.title}}</div>
                                <div class="message-content">{{item.data.content}}</div>
                            </div>
                        </block>
    
                    </div>
                    <div v-else class="empty-message">暂无消息</div>
                </div>
            </div>
        </div>
        <!-- <pageFooter></pageFooter> -->
        <el-drawer
        title="我是标题"
  :visible.sync="drawer"
  :with-header="false"

  >
        <div class="message-detail">
            <div class="message-time">发送时间{{selectedMeaasge?.created_at}}</div>
<div class="message-title">{{selectedMeaasge?.data?.title}}</div>
<div class="message-content">{{selectedMeaasge?.data?.content}}</div>

        </div>
</el-drawer>

    </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import sidebar from "../components/sidebar";
export default {
    components: { sidebar, pageFooter, pageHeader },
    data() {
        return {
           list:[],
           drawer:false,
           selectedMeaasge:{}
           
        };
    },
    created() {
       
    },
    mounted() {
        this.getData();
    },
    methods: {
        // 获取个人信息
        getData() {
            this.$axios.get('/notifications').then(res => {
                if (res.code == 200) {
                   console.log(res)
                   this.list=res.data.data
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        showDetail(item){
            console.log(item)
            this.selectedMeaasge=item
            this.drawer=true
        }
    
    }
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner, .product-put, .productext, .data-input {
		color: #222!important;
	}
.app {
    padding-top: 20px;
    width: 100%;
    margin: 0 auto;
    background: #f1f1f1 100%;
    padding: 0;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 62px;
}
.tit{
    font-size: 18px;
}
.total {
    width: 1280px;
    height: auto;
    margin: auto;
    display: flex;
    align-items: flex-start;
}

.sideright {
    margin-left: 25px;
    width: calc(100% - 265px);
    height: auto;
    background: #ffffff;
    /* padding-bottom: 50px; */
    padding-right: 50px;
    padding-bottom: 125px;
    box-sizing: border-box;
}

.message-list{
    display: flex;
    flex-direction: column;
    padding: 10px 20px;

    .message-item{
        margin-bottom: 20px;
        padding: 5px 5px 15px 5px;
        border-bottom: 1px solid #ccc;

        .message-time {
            color: #333;
            font-size: 12px;
        }

        .message-title {
            color: #333;
            font-size: 14px;
            margin-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .message-content {
            color: #000;
            font-size: 16px;
            margin-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

}

.el-drawer__body .message-detail {
    padding: 10px 20px;
    .message-time {
            color: #333;
            font-size: 12px;
        }

        .message-title {
            color: #333;
            font-size: 14px;
            margin-top: 10px;
        }

        .message-content {
            color: #000;
            font-size: 16px;
            margin-top: 10px;
        }

}

.empty-message {

    text-align: center;
    padding-top: 100px;
}
</style>