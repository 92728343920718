<template>
  <div container>
    <div class="pageHeader" v-if="categoriesList.length">
      <div class="navBox">
        <div class="title">
          <img src="../assets/logo/home-logo.png" @click="router('/home'),tabNum = -1" class="logoImg" />
        </div>
        <div class="tabs" @click.stop>
          <div class="tabItem" @click.stop="tabChange(0)">
            <div class="text">{{ $t('header.AboutUs') }}</div>
            <div class="line" v-show="tabNum == 0"></div>
          </div>
          <div class="tabItem" @click.stop="tabChange(1)"
            @mouseover="companyProfile = true, categoriesId = 1,childrenIndex = 0" @mouseout="companyProfile = false">
            <div class="text">{{ $t('header.CompanyProfile') }}</div>
            <div class="line" v-show="tabNum == 1"></div>
          </div>
          <div class="tabItem" @click.stop="tabChange(2)"
            @mouseover="audioVisualMaterials = true, categoriesId = 1,childrenIndex = 0"
            @mouseout="audioVisualMaterials = false">
            <div class="text">{{ $t('header.AudioVisualMaterials') }}</div>
            <div class="line" v-show="tabNum == 2"></div>
          </div>
          <div class="tabItem" @click.stop="tabChange(3)">
            <div class="text">{{ $t('header.SocialResponsibility') }}</div>
            <div class="line" v-show="tabNum == 3"></div>
          </div>
        </div>
      </div>
      <div class="actionBox">
        <div class="actionBox__item">
          <img class="translateIcon" src="../assets/image/translate.png" @click="language" />
        </div>

        <div class="actionflex" v-if="userInfo">
          <div class="actionBox__item" @click="successlogin" style="padding-left:10px;max-width:300px">
            {{ userInfo.nick_name }}</div>
          <div class="actionBox__item" @click="successlogin">
            {{$i18n.locale == "zh"?'普通会员':'regular members'}}
            <img src="../assets/logo/bottom.png" alt class="bot" />
          </div>
          <div class="botborder" v-show="vipbottom">
            <div class="botborders" @click="router('/sidedata')">
              <img :src="userInfo.avatar_url" alt class="bot-img" />
              <div class="vipright">
                <div class="ritit">{{ userInfo.nick_name }}</div>
                <div class="ritext">{{$i18n.locale == "zh"?'普通会员':'regular members'}}</div>
              </div>
            </div>
            <div class="ungin" @click="tonoshow">{{$i18n.locale == "zh"?'退出':'log out'}}</div>
          </div>
        </div>

        <div class="actionflex" v-else>
          <div class="actionBox__item" @click="tologin">{{ $t('header.login') }}</div>
          <div class="actionBox__item" @click="toregiest">{{ $t('header.register') }}</div>
        </div>
      </div>

      <!-- 下拉 -->

      <img class="expandImg" src="../assets/image/expand.png" alt />

      <div class="navList" v-show="companyProfile" @mouseover="companyProfile = true" @mouseout="companyProfile = false"
        style="text-align: center;">

        <div class="navList__content">
          <div class="navList__item" :style="{'opacity': categoriesId == 1 ? 1 : .4}"
            @mouseover="categoriesId = 1,childrenIndex = 0 " @click="bigs(1)">
            <img src="../assets/icon/icon-trombone2.png" class="navList__item__img">
            <span class="navList__item__text">{{$i18n.locale == "zh"?'管乐':'wind music'}}</span>
          </div>

          <div class="navList__item" :style="{'opacity': categoriesId == 11 ? 1 : .4}"
            @mouseover="categoriesId = 11,childrenIndex = 0" @click="bigs(11)">
            <img src="../assets/icon/icon-violin2.png" class="navList__item__img">
            <span class="navList__item__text">{{$i18n.locale == "zh"?'弦乐':'string music'}}</span>
          </div>

          <div class="navList__item" :style="{'opacity': categoriesId == 12 ? 1 : .4}"
            @mouseover="categoriesId = 12,childrenIndex = 0" @click="bigs(12)">
            <img src="../assets/icon/icon-guitar2.png" class="navList__item__img">
            <span class="navList__item__text">{{$i18n.locale == "zh"?'吉他':'guitar'}}</span>
          </div>

          <div class="navList__item" @mouseover="categoriesId = 13,childrenIndex = 0" >
            <img src="../assets/icon/icon12.png" class="navList__item__img">
            <span
              class="navList__item__text">{{$i18n.locale == "zh"?'产品注册':'Registration of products'}}</span>
          </div>

          <!-- @click="$router.push(`saleafter`)" -->
          <div class="navList__item" @mouseover="categoriesId = 14,childrenIndex = 0">
            <img src="../assets/icon/symbol12.png" class="navList__item__img">
            <span class="navList__item__text">{{$i18n.locale == "zh"?'售后服务':'after-sales service'}}</span>
          </div>
          <!-- @click="$router.push('/inquire')" -->
          <div class="navList__item" @mouseover="categoriesId = 15,childrenIndex = 0" >
            <img src="../assets/icon/symbol22.png" class="navList__item__img">
            <span class="navList__item__text">{{$i18n.locale == "zh"?'经销商查询':'Dealer inquiry'}}</span>
          </div>
          <div class="navList__item" @mouseover="categoriesId = '',childrenIndex = 0" @click="$router.push('/search')">
            <img src="../assets/icon/symbol32.png" class="navList__item__img">
            <span class="navList__item__text">{{$i18n.locale == "zh"?'管乐产品查询':'wind music product query'}}</span>
          </div>
        </div>

        <div class="navList__child" v-if="categoriesId == 1 && categoriesList[0].children_categories.length">
          <div class="navList__child__left">
            <div :class="['navList__child__left-item', {'cur': index == childrenIndex}]"
              v-for="(i, index) in categoriesList[0].children_categories" @mouseover="childrenIndex = index"
              @click="bigs(i.id)" :key="index">
              <div class="navList__child__left-item-text">{{$i18n.locale == "zh"?i.name:i.en_name}}</div>
              <div class="navList__child__left-item-block"></div>
            </div>
          </div>
          <div class="navList__child__right">
            <div class="navList__child__right-item"
              v-for="(i, index) in categoriesList[0].children_categories[childrenIndex].children_categories"
              @click="bigs(i.id)" :key="index">
              <span>{{$i18n.locale == "zh"?i.name:i.en_name}}</span>
            </div>
          </div>
        </div>

        <div class="navList__child" v-if="categoriesId == 11 && categoriesList[1].children_categories.length">
          <div class="navList__child__left">
            <div :class="['navList__child__left-item', {'cur': index == childrenIndex}]"
              v-for="(i, index) in categoriesList[1].children_categories" @mouseover="childrenIndex = index"
              @click="bigs(i.id)" :key="index">
              <div class="navList__child__left-item-text">{{$i18n.locale == "zh"?i.name:i.en_name}}</div>
              <div class="navList__child__left-item-block"></div>
            </div>
          </div>
          <div class="navList__child__right">
            <div class="navList__child__right-item"
              v-for="(i, index) in categoriesList[1].children_categories[childrenIndex].children_categories"
              @click="bigs(i.id)" :key="index">
              <span>{{$i18n.locale == "zh"?i.name:i.en_name}}</span>
            </div>
          </div>
        </div>

        <div class="navList__child" v-if="categoriesId == 12 && categoriesList[2].children_categories.length">
          <div class="navList__child__left">
            <div :class="['navList__child__left-item', {'cur': index == childrenIndex}]"
              v-for="(i, index) in categoriesList[2].children_categories" @mouseover="childrenIndex = index"
              @click="bigs(i.id)" :key="index">
              <div class="navList__child__left-item-text">{{$i18n.locale == "zh"?i.name:i.en_name}}</div>
              <div class="navList__child__left-item-block"></div>
            </div>
          </div>
          <div class="navList__child__right">
            <div class="navList__child__right-item"
              v-for="(i, index) in categoriesList[2].children_categories[childrenIndex].children_categories"
              @click="bigs(i.id)" :key="index">
              <span>{{$i18n.locale == "zh"?i.name:i.en_name}}</span>
            </div>
          </div>
        </div>
        <div class="navList__child" v-if="categoriesId == 13 && categoriesList.length">
          <div class="navList__child__left">
            <div :class="['navList__child__left-item', {'cur': index == childrenIndex}]" :style="{display: i.name==='弦乐'?'none':'flex'}"
              v-for="(i, index) in categoriesList" @mouseover="childrenIndex = index" :key="index" @click="toproduct(i.name)">
              <div class="navList__child__left-item-text">{{$i18n.locale == "zh"?i.name:i.en_name}}</div>
              <div class="navList__child__left-item-block"></div>
            </div>
          </div>
        </div>

        <div class="navList__child" v-if="categoriesId == 14 && categoriesList.length">
          <div class="navList__child__left">
            <!-- <div :class="['navList__child__left-item', {'cur': index == childrenIndex}]"
              v-for="(i, index) in categoriesList" @mouseover="childrenIndex = index"
               @click="$router.push(`saleafter?id=${i.id}`)" :key="index">
              <div class="navList__child__left-item-text">{{$i18n.locale == "zh"?i.name:i.en_name}}</div>
              <div class="navList__child__left-item-block"></div>
            </div> -->
            <div :class="['navList__child__left-item', {'cur': index == childrenIndex}]"
              v-for="(i, index) in sellList" @mouseover="childrenIndex = index"
              @click="$router.push(`/saleafter?id=${i.id}`)" :key="index">
              <div class="navList__child__left-item-text">{{$i18n.locale == "zh"?i.name:i.en_name}}</div>
              <div class="navList__child__left-item-block"></div>
            </div>
          </div>
        </div>

        <div class="navList__child" v-if="categoriesId == 15 && categoriesList.length">
          <div class="navList__child__left">
            <div :class="['navList__child__left-item', {'cur': index == childrenIndex}]"
              v-for="(i, index) in sellList" @mouseover="childrenIndex = index"
              @click="$router.push(`/inquire?type=${i.id}`)" :key="index">
              <div class="navList__child__left-item-text">{{$i18n.locale == "zh"?i.name:i.en_name}}</div>
              <div class="navList__child__left-item-block"></div>
            </div>
          </div>
        </div>

      </div>

      <div class="navList" v-show="audioVisualMaterials" @mouseover="audioVisualMaterials = true"
        @mouseout="audioVisualMaterials = false">

        <div class="navList__content">
          <div class="navList__item" :style="{'opacity': categoriesId == 1 ? 1 : .4}" @mouseover="categoriesId = 1">
            <span class="navList__item__text">{{$i18n.locale == "zh"?'艺术家＆形象大使':'Artist directory'}}</span>
            <div style="margin-top:10px" v-for="(item,index) in sellList" :key="index" @click="$router.push(`/art?type=${item.id}`)">{{$i18n.locale == "zh"?item.name:item.en_name}}</div>
          </div>

          <div class="navList__item" @mouseover="categoriesId = ''" >
            <span class="navList__item__text">{{$i18n.locale == "zh"?'官方视频':'official video'}}</span>
            <div style="margin-top:10px" v-for="(item,index) in sellList" :key="index" @click="$router.push('/offic?type=1&cateType='+item.id)">{{$i18n.locale == "zh"?item.name:item.en_name}}</div>
          </div>
          <!-- @click="$router.push('/offic?type=2')" -->
          <!-- @mouseover="categoriesId = 10" -->
          <div class="navList__item" :style="{'opacity': categoriesId == 10 ? 1 : .4}" >
            <span class="navList__item__text">{{$i18n.locale == "zh"?'产品视频':'product video'}}</span>
            <div style="margin-top:10px" v-for="(item,index) in sellList" :key="index" @click="$router.push('/offic?type=2&cateType='+item.id)">{{$i18n.locale == "zh"?item.name:item.en_name}}</div>
          </div>
        </div>

        <div class="navList__child" v-if="categoriesId == 10 && categoriesList.length > 0">
          <div class="navList__child__left">
            <div :class="['navList__child__left-item', {'cur': index == childrenIndex}]"
              v-for="(i, index) in categoriesList" @mouseover="childrenIndex = index"
              @click="$router.push(`/offic?type=2&id=${i.id}`)" :key="index">
              <div class="navList__child__left-item-text">{{$i18n.locale == "zh"?i.name:i.en_name}}</div>
              <div class="navList__child__left-item-block"></div>
            </div>
          </div>
          <div class="navList__child__right">
            <div class="navList__child__right-item"
              v-for="(i, index) in categoriesList[childrenIndex].children_categories"
              @click="$router.push(`/offic?type=2&id=${i.id}`)" :key="index">
              <span>{{$i18n.locale == "zh"?i.name:i.en_name}}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="navList" v-show="socialResponsibility" @mouseover="socialResponsibility = true" @mouseout="socialResponsibility = false">
				<div class="navItem">
					<div class="navTitle">慰问</div>
				</div>
				<div class="navItem">
					<div class="navTitle">赛事</div>
				</div>
				<div class="navItem">
					<div class="navTitle">赞助</div>
				</div>
            </div>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoriesId: 1,
      childrenIndex: 0,
      companyProfile: false,
      audioVisualMaterials: false,
      socialResponsibility: false,
      token: localStorage.getItem('token'),
      nameshow: false,
      noshow: true,
      vipbottom: false,
      tabNum: -1,
      categoriesList: [],
      userInfo: null,
      sellList:[
      {
        id:1,
        name:'管乐',
        en_name:'Wind instrument'
      },
      {
        id:2,
        name:'吉他',
        en_name:'Guitar'
      },
      {
        id:3,
        name:'提琴',
        en_name:'Violin'
      }
      ]
    };
  },
  methods: {
    router(path) {
      this.vipbottom = false
      this.$router.push({
        path
      });
    },
    tonoshow() {
      this.$confirm(this.$i18n.locale == 'zh' ? '确定退出登录？' : 'Are you sure to log out?')
        .then(_ => {
          localStorage.removeItem('token')
          this.$router.replace('/sign')
        }).catch(_ => { });
    },

    tologin() {
      this.$router.push("/sign");
    },
    toregiest() {
      this.$router.push("/register");
    },
    bigs(id) {
      this.$router.push(`/service?id=${id}`);
      this.tabNum = 1;
    },
    toArt() {
      this.$router.push("/art?type=1");
      this.tabNum = 2;
    },
    toSociology() {
      this.$router.push("/Sociology");
      this.tabNum = 3;
    },
    tabChange(n) {

      if (n == 1) {
        this.bigs()
      } else if (n == 2) {
        this.toArt()
      } else if (n == 3) {
        this.toSociology()
      }

      this.tabNum = n;

      if (n == 0) {
        this.$router.push("/aboutus");
      }
    },
    language() {
      this.$i18n.locale == "zh" ?
        (this.$i18n.locale = "en") :
        (this.$i18n.locale = "zh");
      localStorage.setItem("languageSet", this.$i18n.locale);
    },
    successlogin() {
      this.vipbottom = !this.vipbottom;
    },
    toproduct(type) {
          if(type == '管乐'){
       this.$router.push("/product");
      }else if(type == '吉他'){
        this.$router.push("/productguitar");
      }
    },
    getCategories() {
      this.$axios.get('/categories')
        .then(res => {
          this.categoriesList = res.data
        })
    },
    getUserInfo() {
      this.$axios.get('/user').then(res => {
        if (res.code == 200) {
          this.userInfo = res.data
          this.$store.commit('setUserInfo', res.data)
        } else {
          this.$message(this.$i18n.locale == 'zh' ? '登录使用更多功能' : 'Log in to use more features')
        }
      })
    },
  },
  mounted() {
    this.getCategories();
    this.getUserInfo()
  }
};
</script>

<style lang="scss">
@media screen and (min-width: 750px) {
  .pageHeader {
    min-width: 1280px;
    height: 142px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-bottom: 1px solid #1f1f1f;
    box-sizing: border-box;

    .navBox {
      width: 1200px;
      height: 100%;

      .title {
        width: 100%;
        text-align: center;
        padding: 30px 0 30px 0;
        // margin-bottom: 10px;
        .logoImg {
          width: 225px;
          height: 38px;
        }
      }

      .tabs {
        width: 1200px;
        height: calc(100% - 83px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        text-align: center;
        color: #1f1f1f;
        margin: 0 auto;

        .tabItem {
          flex: 1;
          height: 100%;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .text {
            width: 100%;
          }
          .text:hover {
            font-size: 18px;
            font-weight: 600;
            transition: all 0.2s ease-in-out;
          }

          .line {
            position: relative;
            top: -15px;
            width: 72px;
            height: 4px;
            background: #5c5c5c;
            margin-bottom: 1px;
          }
        }
      }
    }

    .actionBox {
      display: flex;
      align-items: flex-start;
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);

      z-index: 10;

      &__item {
        // width: 80px;
        margin: 0 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .translateIcon {
        cursor: pointer;
        width: 26px;
        height: 26px;
      }
    }

    .expandImg {
      display: none;
    }
  }

  .navList {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    top: 141px;
    z-index: 99999;
    background: #fff;
    border-top: 1px solid #1f1f1f;
    box-shadow: 0 4px 4px 1px rgba(146, 146, 146, 0.5);
    min-height: 200px;

    &__content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 100%;
    }

    &__item {
      // width: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      opacity: 0.4;
      transition: all 0.3s;

      &__img {
        width: 80px;
        height: 80px;
        margin-bottom: 5px;
      }

      &__text {
        font-size: 14px;
        font-weight: 400;
        color: #333;
      }

      &:hover {
        opacity: 1;
      }
    }

    &__child {
      width: 1200px;
      min-height: 180px;
      margin: 20px auto 0;
      padding: 0 0 0 130px;
      border: 1px solid #f8f8f8;
      box-sizing: border-box;
      position: relative;

      &__left {
        width: 130px;
        min-height: 180px;
        background: #f8f8f8;
        position: absolute;
        top: 0;
        left: 0;
      }

      &__left-item {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: relative;
        cursor: pointer;
      }

      &__left-item-text {
        font-size: 14px;
        font-weight: bold;
        color: #b1b1b1;
        transition: all 0.5s;
      }

      &__left-item-block {
        width: 24px;
        height: 4px;
        border-radius: 2px;
        background: transparent;
        margin-top: 10px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        margin: 0 auto;
        transition: all 0.3s;
      }

      &__left-item.cur &__left-item-text {
        color: #333;
      }

      &__left-item.cur &__left-item-block {
        background: #333;
      }

      &__right {
        display: flex;
        flex-wrap: wrap;
      }

      &__right-item {
        width: 25%;
        padding: 20px;
        box-sizing: border-box;

        span {
          padding: 10px 30px;
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          color: #999;
          cursor: pointer;

          &:hover {
            color: #333;
            text-decoration: underline;
          }
        }
      }
    }

    // .navItem {
    // 	margin: 0 40px;

    // 	.navTitle {
    // 		margin-bottom: 5px;
    // 		cursor: pointer;
    // 	}

    // 	.item {
    // 		display: flex;
    // 		align-items: flex-start;
    // 		margin-bottom: 5px;

    // 		.itemTitle {
    // 			margin-right: 9px;
    // 		}

    // 		.itemValue {
    // 			cursor: pointer;

    // 			&:not(:last-child) {
    // 				margin-bottom: 5px;
    // 			}
    // 		}
    // 	}
    // }
  }
}

@media screen and (max-width: 750px) {
  .pageHeader {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 1.2rem;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .navBox {
      width: 100%;

      .title {
        width: 100%;
        font-size: 0.6rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .logoImg {
          width: 3.97rem;
          height: 0.67rem;
        }
      }

      .tabs {
        display: none;
      }
    }

    .actionBox {
      display: none;
    }

    .expandImg {
      width: 0.32rem;
      height: 0.29rem;
      position: absolute;
      right: 0.8rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .navList {
    display: none;
  }
}

.bot {
  width: 10px;
  height: 10px;
}

.bot-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  // background-color: aqua;
}

.botborder {
  padding: 10px;
  box-sizing: border-box;
  width: 200px;
  position: absolute;
  top: 40px;
  z-index: 10;
  background: #fff;
  border-radius: 6px;
  padding-top: 20px;
  box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.15);
}

.botborders {
  display: flex;
  align-items: center;
}

.vipright {
  margin-left: 10px;
  margin-top: 10px;
}

.ritit {
  font-size: 16px;
}

.ritext {
  font-size: 14px;
  color: #636262;
  margin-top: 5px;
}

.ungin {
  width: 150px;
  height: 20px;
  border: 1px solid black;
  margin: auto;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionflex {
  display: flex;
  align-items: flex-start;
}

.active {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 500;
}

.things {
  font-weight: bold;
}
</style>
