<template>
	<div id="app">
		<pageHeader v-if="$route.path != '/sign' && $route.path != '/register' && $route.path != '/forgetpassword'"></pageHeader>
		<router-view />
		<pageFooter v-if="$route.path != '/sign' && $route.path != '/register' && $route.path != '/forgetpassword'"></pageFooter>
	</div>
</template>

<script>
	import pageFooter from "./components/pageFooter.vue";
	import pageHeader from "./components/pageHeader.vue";
	export default {
		components: {
			pageFooter,
			pageHeader
		},
		mounted() {
			if (this._isMobile()) {
			//alert("手机端");
			window.location.href = "https://eastmanmusic.net.cn/h5"
			} else {
			//alert("pc端");
			// window.location.href = "https://eastmanmusic.net.cn"
			}
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
				return flag;
			}
		}
	}
</script>

<style lang="scss">
	// html,
	// body,
	// #app {
	//   width: 100%;
	//   height: 100%;
	//   padding: 0;
	//   margin: 0;
	// }
	* {
		margin: 0;
		padding: 0;
	}
</style>
