module.exports = {
	language: {
		name: '中文'
	},
	header: {
		AboutUs: 'About Us',
		CompanyProfile: 'Company Profile',
		AudioVisualMaterials: 'Audio visual materials',
		SocialResponsibility: 'News and information',
		login: 'login',
		register: 'register'
	},
	home: {
		artist: 'ARTIST'
	}
}
