<template>
  <div v-if="aboutusData">
    <!-- <img :src="aboutusData.index_top[0].cover" class="top-img"> -->
    <div class="banner-box">
        <el-carousel trigger="click" autoplay="true" height="450px" :interval="3000" arrow="always">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <div class="small" @click="$router.push(`/activity?dutyId=${item.id}`)"> -->
            <div class="small">
              <img :src="item.cover" alt class="swiperimg" />
            </div>
            <!-- <div class="swipertext">
							<div class="swipertexts">
								<div class="text-tit">{{item.name}}</div>
								<div class="texts">{{item.short_title}}</div>
								<div class="text-list">{{item.duty_category_name}} I {{item.published_at}}</div>
							</div>
						</div> -->
          </el-carousel-item>
        </el-carousel>

      </div>
    <div class="hard">
      <div class="uscompany">
        {{$i18n.locale == 'zh'?aboutusData.company_profile[0].title:aboutusData.company_profile[0].en_title}}</div>
      <div class="box">
        <div class="boxInner">
          <div class="box-wrapper" :style="showMore?'height:auto':'height:320px'">
            <img class="boxImg" :src="aboutusData.company_profile[0].image_src" alt />
            <span
              class="boxtext">{{$i18n.locale == 'zh'?aboutusData.company_profile[0].description:aboutusData.company_profile[0].en_description}}</span>
          </div>
          <!-- <div class="boxtext" :style="showMore?'height:auto':'height:300px'" 
					v-html="$i18n.locale == 'zh'?aboutusData.company_profile[0].description:aboutusData.company_profile[0].en_description"></div> -->
          <div class="more" v-if="$i18n.locale == 'zh'" @click="showMore=!showMore">{{!showMore?'了解更多':'收起'}}</div>
          <div class="more" v-else @click="showMore=!showMore">{{!showMore?'learn more':'pack up'}}</div>
        </div>
      </div>

      <div class="uscompany">{{$i18n.locale == "zh"?'公司文化':'company culture'}}</div>
      <div class="culture">
        <div v-for="(i, index) in aboutusData.corporate_culture" :key="index" v-if="index < 4" class="culture-for">
          <img :src="i.image_src" alt class="culture" />
          <div class="culturetit-box">
            <div class="culturetit">{{$i18n.locale == 'zh'?i.title:i.en_title}}</div>
            <div class="culturetext" v-html="$i18n.locale == 'zh'?i.description:i.en_description"></div>
          </div>
        </div>
      </div>

      <div class="history">
        <img src="../assets/image/usone.png" alt class="historyimg" />
        <div class="historytit">{{aboutusData.development_history[0].title}}</div>
        <div class="block">
          <div class="deve">{{$i18n.locale == "zh"?'发展历程':'development history'}}</div>
          <el-carousel trigger="click" height="420px" :autoplay=false arrow="always" :loop="false">
            <el-carousel-item>
              <div class="swiperblock">
                <div class="blockun" v-for="(item,index) of aboutusData.development_history" :key="index"
                  v-show="index<4">
                  <div class="block-m"></div>
                  <div class="block-y" v-if="index%2 == 0"></div>
                  <div class="block-s" v-if="index%2 == 0"></div>

                  <div class="block-y on" v-if="index%2 == 1"></div>
                  <div class="block-s  ons" v-if="index%2 == 1"></div>
                  <div class="blocktext" :class="index%2 == 0?'blocktext-bottom':'blocktext-top'">
                    <div class="blockyear">{{item.development_at | year}}{{$i18n.locale == "zh"?'年':'year'}}</div>
                    <div class="yeartext" v-html="$i18n.locale == 'zh'?item.description:item.en_description"></div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="swiperblock">
                <div class="blockun" v-for="(item,index) of aboutusData.development_history" :key="index"
                  v-show="index>3">
                  <div class="block-m"></div>
                  <div class="block-y" v-if="index%2 == 0"></div>
                  <div class="block-s" v-if="index%2 == 0"></div>

                  <div class="block-y on" v-if="index%2 == 1"></div>
                  <div class="block-s  ons" v-if="index%2 == 1"></div>
                  <div class="blocktext" :class="index%2 == 0?'blocktext-bottom':'blocktext-top'">
                    <div class="blockyear">{{item.development_at | year}}{{$i18n.locale == "zh"?'年':'year'}}</div>
                    <div class="yeartext" v-html="$i18n.locale == 'zh'?item.description:item.en_description"></div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="uscompany">{{$i18n.locale == "zh"?'旗下品牌':'sub-brand'}}</div>
      <div class="brand">
        <div class="brands" v-for="(i, index) in aboutusData.its_brands" :key="index" @click="goUrl(i.description)">
          <img :src="i.image_src" alt class="brandimg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      aboutusData: [],
      showMore: false,
      bannerList: [],
    }
  },
  filters: {
    year(value) {
      return value.slice(0, 4)
    }
  },
  methods: {
    goUrl(url) {
      window.open(url)
    },
    getAboutus() {
      this.$axios.get('/index')
        .then(res => {
          this.aboutusData = res.data
          this.aboutusData.development_history.sort(function (a, b) { return a.position - b.position });
        })

    },
    getBanner() {
      this.$axios.get('/banner-list')
        .then(res => {
          console.log(res.data['about_us'])
          this.bannerList = res.data['about_us']

        })
    },
  },
  mounted() {
    this.getAboutus()
    this.getBanner()
  }
};
</script>
<style lang="scss" scoped>
.el-carousel__item h3 {
  font-size: 14px;
  opacity: 1;
  line-height: 150px;
  margin: 0;
}

.top-img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.hard {
  width: 1400px;
  height: auto;
  margin: auto;
}

.box {
  width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin-bottom: 50px;
}
.deve {
  font-size: 26px;
  font-family: Microsoft YaHei;
  // font-weight: bold;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}
.boxImg {
  width: 680px;
  height: 325px;
  margin-right: 15px;
  float: left;
}

.boxInner {
  // width: 560px;
  box-sizing: border-box;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: flex-start;
  font-size: 18px;
  // font-family: PingFang SC;
  font-weight: 500;
  color: #1f1f1f;
}
.box-wrapper {
  overflow: hidden;
}
.boxtext {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1f1f1f;
  white-space: pre-line;
  // overflow: hidden;
  // text-overflow:ellipsis;
  // white-space: nowrap;
}

.more {
  width: 130px;
  height: 34px;
  border: 1px solid #1f1f1f;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1f1f1f;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto 0;
  cursor: pointer;
  &:hover {
    background-color: #1f1f1f;
    border-color: #1f1f1f;
    color: #fff;
  }
}

.uscompany {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1f1f1f;
  line-height: 30px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  overflow: hidden;
}

.culture {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

.culture-for {
  width: 330px;
  height: 400px;
  // border: 1px solid;
  margin-right: 20px;
  box-sizing: border-box;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  .culture {
    width: 100%;
    height: 100%;
  }

  .culturetit-box {
    width: 100%;
    height: 100%;
    padding: 0 30px 70px;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    transition: 0.3s all;
    cursor: pointer;

    .culturetit {
      height: 40px;
      line-height: 40px;
      margin-bottom: 20px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      transition: 0.3s all;
    }

    .culturetext {
      height: 60px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      transition: 0.3s all;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.5);

      .culturetit {
        transform: scale(1.1);
      }

      .culturetext {
        transform: scale(1.1);
      }
    }
  }
}

.history {
  width: 100%;
  height: 500px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  overflow: hidden;

  .block {
    margin-top: 30px;
    width: 100%;
  }
}

.brand {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.brands {
  width: calc(25% - 30px);
  height: 150px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-left: 20px;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  cursor: pointer;
}

.brandimg {
  width: 170px;
  height: 100px;
  object-fit: contain;
}

.historyimg {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}

.historytit {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 30px;
  position: absolute;
  top: 60px;
  left: 590px;
}

/* 轮播 */
.swiperblock {
  width: 1100px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blockun {
  width: 250px;
  height: auto;
  position: relative;
}

.block-m {
  width: 100%;
  height: 4px;
  background: #ffffff;
}

.block-y {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: -9px;
  left: 115px;
}

.block-s {
  width: 4px;
  height: 60px;
  background: #ffffff;
  position: absolute;
  left: 123px;
}

.on {
  position: absolute;
  left: 115px;
}

.ons {
  position: absolute;
  left: 123px;
  top: -65px;
}

.blockyear {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.yeartext {
  width: 230px;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.blocktext {
  position: absolute;
}

.blockyear {
  margin-left: 65px;
}

.blocktext-bottom {
  top: 60px;
  left: -20px;
}

.blocktext-top {
  top: -150px;
  left: 20px;
}

.banner-box {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  min-width: 1280px;

  .swipertext {
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    display: flex;
    align-items: center;
  }

  .swipertexts {
  }
  .small {
    height: 100%;
    width: 100%;
  }
}

.swiperimg{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
</style>
