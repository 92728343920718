<template>
    <div>
        <!-- <pageHeader></pageHeader> -->
        <div class="app">
            <div class="total">
                <sidebar class="sidebar" :active="3"></sidebar>
                <div class="sideright">
                    <div class="end">
                        <div
                            class="ended"
                            v-for="(item,index) of List"
                            :key="index"
                            :class="{ active: index == isActive }"
                            @click="checkItem(index)"
                        >
                            {{$i18n.locale == "zh"?item: en_List[index]}}
                            <div class="end-line" v-if="isActive == index"></div>
                        </div>
                    </div>
                    <div class="end-text" v-if="activityList.length > 0">
                        <div class="end-big" v-for="(item,index) of activityList" :key="index"
                        @click="$router.push('/sidetext?activity_category_id=' + item.activity_id)">
                            <div class="left-img">
                                <img :src="item.activity.activity_cover" alt class="imgs" />
                            </div>
                            <div class="right-text" v-if="item.activity">
                                <div class="activity-text">{{$i18n.locale == "zh"?item.activity.name:item.activity.en_name}}</div>
                                <div class="activity-tion">{{$i18n.locale == "zh"?item.activity.activity_category.name:item.activity.activity_category.en_name}}</div>
                                <div
                                    class="activity-time"
                                >{{$i18n.locale == "zh"?'活动时间':'activity time'}}：{{ item.activity.activity_start_time }} - {{ item.activity.activity_end_time }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="empty">{{$i18n.locale == "zh"?'暂无内容':'No content'}}</div>
                </div>
            </div>
        </div>
        <!-- <pageFooter></pageFooter> -->
    </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import sidebar from "../components/sidebar";
export default {
    components: { sidebar, pageFooter, pageHeader },
    data() {
        return {
            List: ["已发起", "进行中", "已结束"],
            en_List: ["launched", "underway", "finished"],
            isActive: 0,
            activityList: []
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        checkItem(index) {
            this.isActive = index;
            this.getList()
        },
        getList() {
            this.$axios.get('/user_enlist_activities?is_handle_status=' + this.isActive).then(res => {
                this.activityList = res.data
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.app {
    padding-top: 20px;
    width: 100%;
    margin: 0 auto;
    background: #f1f1f1 100%;
    padding: 0;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 62px;
}

.total {
    width: 1280px;
    height: auto;
    margin: auto;
    display: flex;
    align-items: flex-start;
}

.sideright {
    margin-left: 25px;
    width: calc(100% - 265px);
    height: auto;
    background: #ffffff;
    /* padding-bottom: 50px; */
    padding-right: 50px;
    box-sizing: border-box;
}
.end {
    display: flex;
    align-items: center;
    margin-left: 51px;
}
.ended {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    margin-right: 99px;
    margin-top: 23px;
    cursor: pointer;
}
.active {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1f1f1f;
    line-height: 30px;
}
.end-line {
    width: 46px;
    height: 3px;
    background: #1f1f1f;
    margin: auto;
    margin-top: 5px;
}
.end-text {
    margin-left: 51px;
}
.end-big {
    display: flex;
    align-items: flex-start;
    padding-bottom: 30px;
    padding-top: 30px;
    &:not(:last-child) {
        border-bottom: 1px solid #cdcdcd;
    }
}
.left-img {
    width: 164px;
    height: 100px;
    border-radius: 4px;
}
.imgs {
    width: 100%;
    height: 100%;
    border: 0;
}
.right-text {
    margin-left: 9px;
}
.activity-text {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
}
.activity-tion {
    // width: 72px;
    padding: 0 8px;
    display: inline-block;
    height: 24px;
    background: #e7e7e7;
    border-radius: 4px;
    line-height: 24px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    margin-top: 5px;
}
.activity-time {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    margin-top: 10px;
}
.empty{
    width: 100%;
    height: 100px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
</style>