<template>
    <div>
        <!-- <pageHeader></pageHeader> -->
        <div class="app">
            <div class="total">
                <sidebar class="sidebar" :active="6"></sidebar>
                <div class="sideright">
                    <div class="passright">
                        <div class="phone">{{$i18n.locale == "zh"?'请填写你的手机号，并进行验证修改':'Please fill in your mobile phone number and verify the modification'}}:</div>
                        <div class="flex">
                            <input type="text" class="inp" v-model="form.mobile" 
                            :placeholder="$i18n.locale == 'zh'?'请输入手机号码':'Please enter your mobile phone number'" />
                            
                            <div class="obtain" @click="getSms" v-if="codeStatus == 1">{{$i18n.locale == "zh"?'获取验证码':'Obtaining verification code'}}</div>
                            <div class="send" v-else>{{$i18n.locale == "zh"?'已发送验证码':'The verification code has been sent'}}（{{ codeTime }}）</div>
                        </div>
                        <input v-model="form.code" type="text" class="inptwo" 
                        :placeholder="$i18n.locale == 'zh'?'请输入验证码':'Please enter the verification code'" autocomplete="new-password" />
                        <div class="care">{{$i18n.locale == "zh"?'验证码只能使用一次，30分钟内有效':'The verification code can be used only once and is valid within 30 minutes'}}</div>
                        <input
                            v-model="form.password"
                            type="password"
                            class="inpthree"
                            :placeholder="$i18n.locale == 'zh'?'新密码':'The new password'"
                            autocomplete="new-password"
                        />
                        <br />
                        <input
                            v-model="form.password_confirmation"
                            type="password"
                            class="inpthree"
                            :placeholder="$i18n.locale == 'zh'?'确认新密码':'Confirm the new password'"
                        />
                        <div class="preser" @click="save">{{$i18n.locale == "zh"?'保存':'save'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <pageFooter></pageFooter> -->
    </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import sidebar from "../components/sidebar";
export default {
    components: { sidebar, pageFooter, pageHeader },
    data() {
        return {
            form: {
                mobile: '',
                code: '',
                password: '',
                password_confirmation: ''
            },
            codeTime: 60,
            codeStatus: 1,
            codeTimeout: null
        }
    },
    methods: {
        save() {
            this.$axios.post('/oauth/mobile_reset_password', this.form).then(res => {
                if (res.code != 200) {
                    this.$message.error(res.message);
                } else if (res.code == 200) {
                    this.$message.success(res.message);
                    this.codeTimeout = null
                    this.codeTime = 60
                    this.codeStatus = 1
                    for (const key in this.form) {
                        this.form[key] = ""
                    }
                }
            })
        },
        getSms() {
            this.$axios.post('/oauth/send_code', {
                mobile: this.form.mobile,
                usage: "forgot_password"
            }).then(res => {
                console.log(res);
                if (res.code == 422) {
                    this.$message.error(res.message);
                } else if (res.code == 200) {
                    this.$message.success('验证码已发送');
                    this.codeStatus = 2
                    this.codeTime = 60
                    this.codeTimeout = setInterval(() => {
                        this.codeTime--
                        if (this.codeTime == 0) {
                            this.codeStatus = 1
                            this.codeTime = 60
                            clearInterval(this.codeTimeout)
                        }
                    }, 1000)
                }
            });
        }
    },
};
</script>
<style scoped>
.app {
    width: 100%;
    margin: 0 auto;
    background: #f1f1f1 100%;
    padding: 0;
    padding-top: 20px;
}

.total {
    width: 1280px;
    height: auto;
    margin: auto;
    display: flex;
    align-items: flex-start;
}

.sideright {
    margin-left: 25px;
    width: calc(100% - 265px);
    height: auto;
    background: #ffffff;
    margin-bottom: 62px;
}
.passright {
    margin-top: 51px;
    margin-left: 31px;
}
.phone {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
}
.flex {
    display: flex;
    align-items: center;
    margin-top: 30px;
}
.inp {
    width: 300px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #cdcdcd;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #444;
    line-height: 30px;
    padding-left: 10px;
    outline: none;
}
.inptwo {
    width: 300px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #cdcdcd;
    margin-top: 21px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #444;
    line-height: 30px;
    padding-left: 10px;
    outline: none;
}
.inpthree {
    width: 300px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #cdcdcd;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #444;
    line-height: 30px;
    padding-left: 10px;
    outline: none;
    margin-bottom: 22px;
}
.obtain {
    width: 110px;
    height: 40px;
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    border-radius: 4px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    margin-left: 30px;
}
.send {
    width: auto;
    height: 40px;
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    border-radius: 4px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    display: flex;
    align-items: center;
    margin-left: 12px;
    padding-left: 20px;
    padding-right: 20px;
}
.care {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 19px;
}
.preser {
    width: 180px;
    height: 44px;
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 90px;
    margin-top: 23px;
    margin-bottom: 537px;
    cursor: pointer;
}
</style>