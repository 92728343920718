import { render, staticRenderFns } from "./sidepass.vue?vue&type=template&id=0599bb39&scoped=true&"
import script from "./sidepass.vue?vue&type=script&lang=js&"
export * from "./sidepass.vue?vue&type=script&lang=js&"
import style0 from "./sidepass.vue?vue&type=style&index=0&id=0599bb39&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0599bb39",
  null
  
)

export default component.exports