<template>
	<div>
		<div class="block">
			<span class="demonstration"></span>
			<!-- <img src="../assets/image/background.png" alt class="swiperimg" style="height:450px" /> -->
			<div class="banner-box">
        <el-carousel
          trigger="click"
          autoplay="true"
          height="450px"
          :interval="3000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <div class="small" @click="$router.push(`/activity?dutyId=${item.id}`)"> -->
            <div class="small">
              <img :src="item.cover" alt class="swiperimg" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
			<div class="swipernext">
				<div class="aervicesou">
					<input type="text" :placeholder="$i18n.locale == 'zh'?'输入产品编号查询对应产品信息':'Enter the product number to query the product information'" class="sou" v-model="keywords" @keyup.enter="getData"><i class="el-icon-search" @click="getData"></i>
				</div>
				<div v-if="list.goods">
					<div class="productContainer">
						<div class="top">
							<div class="title">{{$i18n.locale == "zh"?list.goods.goods_name:list.goods.en_goods_name}}</div>
							<div class="param">{{$i18n.locale == "zh"?'类型':'type'}}：{{$i18n.locale == "zh"?list.goods.category_name:list.goods.category_en_name}}</div>
							<div class="param">{{$i18n.locale == "zh"?'产品简介':'Product introduction'}}：{{$i18n.locale == "zh"?list.goods.short_description:list.goods.en_short_description}}</div>
							<div style="font-size:30px;margin:30px 0">{{$i18n.locale == "zh"?'参考价格':'Reference price'}}￥{{list.goods.price}}</div>
							<div class="detailName">{{$i18n.locale == "zh"?'产品详情图':'Product Detail map'}}</div>
						</div>
						<img v-for="(item,index) in list.goods.covers" :key="index" class="detailImg" :src="item" />
					</div>
				</div>
				<div v-else style="text-align:center;padding:50px 0">{{$i18n.locale == "zh"?'暂无内容':'No content'}}</div>
				<div class="page-box">
					<el-pagination hide-on-single-page="false" :total="list.length"
					 :background="true" layout="prev, pager, next"   calss="pagin"></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import pageFooter from "../components/pageFooter.vue";
	import pageHeader from "../components/pageHeader.vue";
	export default {
		components: {
			pageFooter,
			pageHeader
		},
		data() {
			return {
				list: [],
				keywords:'',
				bannerList:[],
			}
		},
		mounted() {
			this.getBanner();
		},
		methods: {
			getData(){
				this.$axios.get('/get_goods_card_number?card_no='+this.keywords).then(res=>{
					if(res.code == 200){
						this.list = res.data
					}else{
						this.$message.error(res.message)
					}
				})
			},
			getBanner() {
        this.$axios.get("/banner-list").then((res) => {
          this.bannerList = res.data["product_query"];
        });
      },
			// toGoodsDetails(id) {
			// 	this.$router.push({
			// 		name: 'Detail',
			// 		params: {
			// 			id
			// 		}
			// 	})
			// },
			// getList() {
			// 	this.$axios.get(`/goods?keyword=${this.keywords}`)
			// 		.then(res => {
			// 			this.list = res.data
			// 		})
			// },
		},
	};
</script>
<style lang="scss" scoped>
	// ::v-deep .el-carousel__button {
	// 	border: 1px solid gray;
	// 	width: 14px;
	// 	height: 14px;
	// 	border-radius: 50%;
	// 	background: #000;
	// }
	.productContainer{
		.top{
			padding: 30px;
			box-sizing: border-box;
			.title{
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 500;
				color: #333333;
			}
			.param{
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: 500;
				color: #999999;
				margin: 30px 0 50px;
			}
			.detailName{
				width: 100%;
				font-size: 32px;
				font-family: Microsoft YaHei;
				font-weight: 500;
				color: #333333;
				text-align: center;
			}
		}
		.detailImg{
			width: 100%;
		}
	}
	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce6;
	}

	.swiperimg {
		width: 100%;
		object-fit: cover;
	}

	.swipernext {
		width: 1280px;
		height: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.el-icon-search {
		font-size: 20px;
		cursor: pointer;
	}

	.sou {
		flex: 1;
		height: 100%;
		background: #FFFFFF;
		border: none;
		outline: none;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #1F1F1F;
	}

	.aervicesou {
		margin-top: 30px;
		padding: 0 30px;
		width: 800px;
		height: 60px;
		box-sizing: border-box;
		margin-left: 200px;
		display: flex;
		align-items: center;
		border: 1px solid #1F1F1F;
		border-radius: 4px;
	}

	.activimg {
		width: 100%;
		height: 100%;
	}

	.bottomun {
		margin-top: 150px;
	}

	.serviceimg {
		width: 200px;
		height: 200px;
		background: #ffffff;
		border: 1px solid #e8e8e8;
		cursor: pointer;
		border-radius: 4px;
	}

	.service-for {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 50px;
	}

	.service-index {
		width: calc(50% - 31px);
		display: flex;
		align-items: center;
		margin-top: 30px;

	}

	.service-right {
		margin-left: 10px;
	}

	.serviceone {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #1f1f1f;
		line-height: 30px;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		cursor: pointer;
	}

	.servicetwo {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #1F1F1F;
		line-height: 30px;
		margin-top: 10px;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
		cursor: pointer;
		white-space: pre-line;
	}

	.servicethree {
		width: 160px;
		height: 32px;
		border: 1px solid #1F1F1F;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
		cursor: pointer;
	}

	.page-box {
		padding-bottom: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.banner-box {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
		min-width: 1280px;
      
        .swipertext {
          width: 1280px;
          height: 100%;
          margin: 0 auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 20;
          display: flex;
          align-items: center;
        }
      
        .swipertexts {
        }
        .small {
          height: 100%;
          width: 100%;
          img{
            width:100%;
            height: 450px;
          }
        }
      }
</style>
