<template>
	<div>
		<div class="arttitle">
			<div class="titletext">{{$i18n.locale == "zh"?'经销商查询':'Dealer inquiry'}}</div>
			<div class="banner-box">
        <el-carousel
          trigger="click"
          autoplay="true"
          height="450px"
          :interval="3000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <div class="small" @click="$router.push(`/activity?dutyId=${item.id}`)"> -->
            <div class="small">
              <img :src="item.cover" alt class="swiperimg" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
		</div>
		<div class="hard">
			<div class="box">
				<img class="boxImg" src="../assets/image/boxImg.png" alt />
				<div class="boxInner">
					<div style="font-size: 24px">{{top.web_company_name}}</div>
					<div class="boxtext"  v-html="top.web_company_des"></div>
					<div class="boxphone">
						<!-- {{$i18n.locale == "zh"?'电话':'phone'}}：{{top.web_company_tel}}
						<br />{{$i18n.locale == "zh"?'邮箱':'email'}}：{{top.web_company_mail}} -->
						{{$i18n.locale == "zh"?'地址':'address'}}：{{top.web_company_address}}
					</div>
				</div>
			</div>
			<div class="baidumap">
				<div class="search-box">
					<input class="search-input" type="text" v-model="keywords" :placeholder="$i18n.locale == 'zh'?'请输入经销商名称':'Please enter the dealer name'" @keyup.enter="getData" >
					<div class="eli"><i class="el-icon-search" @click="getData"></i></div> 
				</div>
				<div class="search-list-box">
					<div class="search-list-item" :class="{'search-item-change':searchItemChange == index}"
					v-for="(item, index) in markers" :key="index" @click="searchItem(item),searchItemChange = index">
						<div class="name">{{item.name}}</div>
						<div class="address">{{$i18n.locale == "zh"?'地址':'address'}}：{{item.address}}</div>
						<div class="tel">{{$i18n.locale == "zh"?'联系电话':'tel'}}：{{item.tel}}</div>
					</div>
				</div>
				<!-- <baidu-map class="bm-view"  :center="center"  :zoom="zoom" :scroll-wheel-zoom="true">
					<bm-local-search :keyword="keyword" :auto-viewport="true"> </bm-local-search>
					<bm-marker  v-for="(item, index) in markers" :key="index" v-if="item.lng&&item.lat"
					:position="{lng: item.lng, lat: item.lat}" @click="show = index" >
						<bm-info-window :show="show == index" style="font-size: 16px">
							<div>
								<div class="name">{{item.name}}</div>
		
								
								<div class="address">{{$i18n.locale == "zh"?'地址':'address'}}：{{$i18n.locale == "zh" ? item.address:item.en_address}}</div>
							</div>
							
						</bm-info-window>
					</bm-marker>
				</baidu-map> -->
				
			</div>
		</div>
	</div>
</template>

<script>
	import pageFooter from "../components/pageFooter.vue";
	import pageHeader from "../components/pageHeader.vue";

	export default {
		components: {
			pageFooter,
			pageHeader
		},
		name: "TestBaiDu",
		data() {
			return {
				center: {
					lng: 105,
					lat: 32
				},
				zoom: 6,
				keyword: '',
				//地图标记
				markers: [],
				show: -1,
				banner: [],
				top: '',
				distributor: [],
				selectItem: null,
				searchItemChange :-1,
				type:'',
				bannerList:[],
			};
		},
		mounted() {
			this.getData()
			this.getBanner()
		},
		watch:{
            $route:{
                handler(nv,ov){
					this.getData()
                }
            },
            deep:true
        },
		methods: {
			searchItem(v) {
				this.center.lng = v.lng
				this.center.lat = v.lat
				this.zoom = 16
			},
			handler({
				BMap,
				map
			}) {
				console.log(BMap, map)
				this.center.lng = 116.404
				this.center.lat = 39.915
				this.zoom = 15
			},
			// 点击地图标记
			markertap(id){
				this.distributor.forEach(item=>{
					if(item.id == id){
						this.selectItem = item
					}
				})
			},
			getData(){
				this.selectItem = null
				let url = '/distributor_index?keyword='+(this.keywords || '')+ '&&type='+this.$route.query.type
				// if(this.keywords) {
				// 	url = '/distributor_index?keyword='+this.keywords
				// } else {
				// 	url = '/distributor_index'
				// }
				this.$axios.get(url).then(res=>{
					if(res.code == 200){
						this.banner = res.data.banner
						this.distributor = JSON.parse(JSON.stringify(res.data.distributor))
						this.top = res.data.top
						this.markers = []
						this.$message.success(this.$i18n.locale == 'zh'?'搜索成功':'Search success')
						res.data.distributor.forEach(item=>{
							let obj = {
								id: item.id,
								width: '20',
								lat: item.lng_lat?.split(',')?.[1],
								lng: item.lng_lat?.split(',')?.[0],
								name: item.name,
								tel: item.tel,
								address: item.address,

							}
							this.markers.push(obj)
						})
						// console.log(this.markers,123)
					}
				})
			},
			getBanner() {
        this.$axios.get("/banner-list").then((res) => {
          this.bannerList = res.data["product_service"];
        });
      },

		}
	};
</script>
<style lang="scss" scoped>
	.bm-view {
		width: 100%;
		height: 600px;
	}

	.titletext {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 50;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}

	.arttitle {
		width: 100%;
		height: 450px;
		position: relative;
		// background-image: url("../assets/image/background.png");
		// background-position: center center;
	}

	.hard {
		width: 1280px;
		height: auto;
		margin: auto;
	}

	.box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 50px;
		margin-top: 57px;
	}

	.boxImg {
		width: 680px;
		height: 325px;
	}

	.boxInner {
		width: 560px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 500;
		color: #1f1f1f;
	}

	.boxtext {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #1f1f1f;
		line-height: 30px;
	}

	.boxphone {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #1f1f1f;
		line-height: 30px;
	}

	#tangram-suggestion--TANGRAM__1j-input {
		width: 300px !important;
		height: 38px !important;
		background: #FFFFFF !important;
		box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.23) !important;
		outline: none;
		border: none;
		margin-top: 30px;
		margin-left: 30px;
		padding-left: 20px;

	}

	.baidumap {
		position: relative;
		padding-top: 80px;
		margin-bottom: 65px;
	}
	.name {
		font-weight: bold;
		margin-bottom: 5px;
	}
	.search-box {
		z-index: 100;
		position: absolute;
		left: 50%; top: 0;
		transform: translateX(-50%); 
	}
	.search-input {
		height:56px;
		width:680px;
		background: #F8F8F8;;
		border-radius: 28px;
		padding: 0 60px;
		border: none;
		font-size: 18px;
	}
	.search-list-box {
		height:600px;
		// width:364px;
		background:#F8F8F8;
		// position: absolute;
		left: 0;
		top: 80px;
		z-index: 100;
		overflow-y: auto;
		
		.search-list-item {
			height: 164px;
			color: #222;
			padding: 10px 20px;
			font-size: 16px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			// align-items: center;
			.name {
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 10px;
			}
			.address {
				line-height: 26px;
				color: #666;
			}
		}
		.search-item-change {
			background-color: #1f1f1f;
			color: #FFF;
			.address {
				color: #FFF;
			}
		}
		// &::-webkit-scrollbar{
		// 	display:none;
		// }
	}
	.eli {
		height: 40px;
		width:40px;
		font-size: 22px;
		font-weight: 600;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 10px;
		top: 10px;
	}
	.banner-box {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
		min-width: 1280px;
      
        .swipertext {
          width: 1280px;
          height: 100%;
          margin: 0 auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 20;
          display: flex;
          align-items: center;
        }
      
        .swipertexts {
        }
        .small {
          height: 100%;
          width: 100%;
          .swiperimg{
            width: 100%;
            height: 450px;
            object-fit: cover;
          }
        }
      }
</style>
