<template>
  <div class="pageFooter">
    <div class="optionList">
      <div class="optionItem">
        <div class="optionItemTitle" @click="activeChange(0)">
          <span>{{ $t('header.CompanyProfile') }}</span>
          <span>+</span>
        </div>
        <ul class="list" :style="{ 'height': activeNum == 0 ? '2.1rem' : 0 }">
          <li @click="$router.push('/product')">{{$i18n.locale == "zh"?'管乐产品注册':'Registration of wind music products'}}
          </li>
          <li @click="$router.push('/saleafter')">{{$i18n.locale == "zh"?'售后服务':'after-sales service'}}</li>
          <li @click="$router.push('/inquire')">{{$i18n.locale == "zh"?'经销商查询':'Dealer inquiry'}}</li>
          <li @click="$router.push('/search')">{{$i18n.locale == "zh"?'产品查询':'product query'}}</li>
        </ul>
      </div>
      <div class="optionItem">
        <div class="optionItemTitle" @click="activeChange(1)">
          <span>{{ $t('header.AudioVisualMaterials') }}</span>
          <span>+</span>
        </div>
        <ul class="list" :style="{ 'height': activeNum == 1 ? '2.1rem' : 0 }">
          <li @click="$router.push('/art')">{{$i18n.locale == "zh"?'艺术家＆形象大使':'Artist directory'}}</li>
          <li @click="$router.push('/offic?type=1')">{{$i18n.locale == "zh"?'官方视频':'official video'}}</li>
          <li @click="$router.push('/offic?type=2')">{{$i18n.locale == "zh"?'产品视频':'product video'}}</li>
        </ul>
      </div>
      <div class="optionItem">
        <div class="optionItemTitle" @click="activeChange(2)">
          <span>{{ $t('header.AboutUs') }}</span>
          <span>+</span>
        </div>
        <ul class="list" :style="{ 'height': activeNum == 2 ? '2.1rem' : 0 }" @click="$router.push('/aboutus')">
          <li>{{$i18n.locale == "zh"?'公司简介':'company profile'}}</li>
          <li>{{$i18n.locale == "zh"?'发展历程':'development history'}}</li>
          <li>{{$i18n.locale == "zh"?'旗下品牌':'sub-brand'}}</li>
        </ul>
      </div>
      <div class="title">EASTMAN</div>
    </div>
    <div class="label">
      <!-- <img src="../assets/image/beian.png" alt=""> -->
      <span>©2021{{$i18n.locale == "zh"?'伊斯曼音乐公司版权所有':'Eastman music Co, LTD all rights reserved'}}
        <a href="">{{$i18n.locale == "zh"?'隐私政策':'privacy policy'}}</a>
        <a href="">{{$i18n.locale == "zh"?'条款与条件':'terms and Conditions'}}</a>
        &nbsp; &nbsp; &nbsp; &nbsp;
        {{$i18n.locale == "zh"?'京公网安备11011302003828号':'Jing Wan Secure number 11011302003828 '}}
        &nbsp; &nbsp; &nbsp; &nbsp;
        {{$i18n.locale == "zh"?'京ICP备2021039062号':'Jing ICP Secure number 2021039062'}}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNum: -1,
      windowWidth: 0 // 页面可视区域宽度
    }
  },
  mounted() {
    window.addEventListener('resize', this.resize)
    this.windowWidth = window.innerWidth
    if (this.windowWidth < 750) {
      this.activeNum = 0
    }
  },
  methods: {
    activeChange(n) {
      if (this.windowWidth < 750) {
        this.activeNum = n
      }
    },
    resize() {
      this.windowWidth = window.innerWidth
      if (this.windowWidth < 750) {
        this.activeNum = 0
      }
    }
  }
}
</script>

<style lang="scss">
@media screen and (min-width: 750px) {
  .pageFooter {
    min-width: 1280px;
    height: 337px;
    background-color: #1f1f1f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    box-sizing: border-box;

    .optionList {
      width: 100%;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      // align-items: center;
      justify-content: space-around;
      // padding: 0 270px;
      box-sizing: border-box;

      .optionItem {
        .optionItemTitle {
          font-size: 18px;

          span:nth-child(2) {
            display: none;
          }
        }

        .list {
          height: auto !important;
          list-style: none;
          padding: 0;
          margin: 0;
          cursor: pointer;

          li {
            margin-top: 25px;
          }
        }
      }

      .title {
        font-size: 60px;
        line-height: 170px;
      }
    }

    .label {
      width: 100%;
      height: 60px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        height: 24px;
        margin: 0 10px;
      }
      a {
        color: #fff;
        text-decoration: none;
        margin: 0 5px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .pageFooter {
    width: 100%;
    height: 6.51rem;
    background-color: #1f1f1f;
    padding: 0.53rem 0.4rem 0.29rem;
    box-sizing: border-box;
    position: relative;

    .optionList {
      .title {
        display: none;
      }

      .optionItem {
        margin-bottom: 0.43rem;

        .optionItemTitle {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.37rem;
          font-family: Microsoft YaHei;
          font-weight: 500;
          color: #ffffff;
          margin-bottom: 0.1rem;
        }

        .list {
          list-style: none;
          font-size: 0.32rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          padding-left: 0.27rem;
          transition: all 0.3s;
          margin: 0;
          overflow: hidden;

          li {
            width: 100%;
            height: 0.7rem;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .label {
      width: 100%;
      font-size: 0.27rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      position: absolute;
      bottom: 0.43rem;
      left: 0;
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
