<template>  <div>
    <div class="arttitle">
      <div class="titletext">
        {{ $i18n.locale == "zh" ? "艺术家名录" : "Artist directory" }}
      </div>
      <!-- <img src="../assets/image/background.png" alt class="arttitimg" /> -->
      <div class="banner-box">
    <el-carousel trigger="click" autoplay="true" height="450px" :interval="3000" arrow="always">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <!-- <div class="small" @click="$router.push(`/activity?dutyId=${item.id}`)"> -->
        <div class="small">
          <img :src="item.cover" alt class="swiperimg"  />
        </div>
      </el-carousel-item>
    </el-carousel>

  </div>

    </div>
    <div class="hard">
      <div class="ArtistsBox">
        <div class="artistsList" v-if="artData.length">
          <div
            class="artistsItem"
            v-for="(i, index) of artData"
            @mouseover="nameIndex = index"
            @mouseleave="nameIndex = -1"
          >
            <img class="artistsItemImg" :src="i.artist_avatars" alt />
            <div class="show-name" v-show="nameIndex != index">
              {{ $i18n.locale == "zh" ? i.name : i.en_name }}
            </div>
            <div
              class="artistsItemHover"
              :style="{ opacity: index === selIndex ? 1 : 0 }"
              @mousemove="selIndex = index"
              @mouseout="selIndex = null"
              @click="$router.push(`/artdire?id=${i.id}`)"
            >
              <div class="name">
                {{ $i18n.locale == "zh" ? i.name : i.en_name }}
              </div>
              <div class="desc">
                <!-- <div class="desc-text" v-html="i.description"></div> -->
                <div
                  class="rank_name"
                  v-for="(item, index) in i.artist_ranks"
                  :key="index"
                >
                  {{
                    $i18n.locale == "zh" ? item.rank_name : item.en_rank_name
                  }}
                </div>
              </div>
              <button class="moreBtn">
                {{ $i18n.locale == "zh" ? "查看详情" : "view details" }} >
              </button>
            </div>
          </div>

        </div>
      </div>
      <div>暂无数据</div>
      <!-- <el-pagination background layout="prev, pager, next" hide-on-single-page="false" :total="artData.length"
        calss="pagin"></el-pagination> -->
    </div>
  </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";

export default {
  components: {
    pageFooter,
    pageHeader,
  },
  data() {
    return {
      selIndex: "",
      artData: "",
      nameIndex: -1,
      bannerList:[],
    };
  },
  watch: {
    $route: {
      handler(nv, ov) {
        this.getArt();
      },
    },
    deep: true,
  },
  methods: {
    getArt() {
      this.$axios.get("/artist?type=" + this.$route.query.type).then((res) => {
        this.artData = res.data;
      });
    },
    getBanner() {
      this.$axios.get('/banner-list')
        .then(res => {
          this.bannerList = res.data['artists']

        })
    },
  },
  mounted() {
    this.getArt();
    this.getBanner();
  },
};
</script>
<style scoped>
  
.banner-box {
  position: relative;
  max-width: 1920px !important;
  margin: 0 auto;
  min-width: 1280px;
  .swipertext {
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    display: flex;
    align-items: center;
  }

  .swipertexts {
  }
  .small {
    height: 100%;
    width: 100%;
  }
}

.titletext {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.arttitle {
  width: 100%;
  height: 450px;
  position: relative;
}

.arttitimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hard {
  width: 1400px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.ArtistsBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #1f1f1f;
  margin-bottom: 50px;
}

.title {
  margin: 30px 0 0;
}

.artistsList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.artistsItem {
  width: 332px;
  height: 402px;
  margin-right: 20px;
  margin-top: 30px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.artistsItem:nth-of-type(4n) {
  margin-right: 0;
}

.artistsItemImg {
  width: auto;
  height: 100%;
}

.artistsItem:hover .artistsItemHover {
  display: flex;
}

.artistsItemHover {
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  background-color: rgba(31, 31, 31, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  transition: 0.5s all;
  /* display: none; */
}
.show-name {
  padding: 3px 0;
  width: 60%;
  position: absolute;
  top: 270px;
  font-size: 22px;
  color: #1f1f1f;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  background: rgba(243, 243, 243, 0.8);
}
.name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  /* font-weight: bold; */
  color: #ffffff;
}

.desc {
  padding: 0 30px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  margin: 30px 0 40px;
  height: 100px;
  overflow: hidden;
}

.desc-text {
  width: 100%;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.rank_name {
  width: 100%;
  word-break: break-all;
  padding: 5px 0;
}
.moreBtn {
  width: 180px;
  height: 50px;
  background: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #1f1f1f;
  border: 0;
  outline: 0;
  border-radius: 5px;
  cursor: pointer;
}
.swiperimg{
            width: 100%;
            height: 450px;
            object-fit: cover;
          }
</style>
