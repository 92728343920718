<template>
  <div>
    <div class="hard">
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item :to="{ path: '/' }">{{$i18n.locale == "zh"?'首页':'Home page'}}</el-breadcrumb-item>
			  <el-breadcrumb-item :to="{ path: '/service' }" >{{$i18n.locale == "zh"?'产品列表':'Product list'}}</el-breadcrumb-item>
			  <el-breadcrumb-item>{{$i18n.locale == "zh"?'产品详情':'Product details'}}</el-breadcrumb-item>
			</el-breadcrumb> -->
      <div class="detail-top">
        <div class="detailleft">
          <div alt :class="['detailleft-img', {'hover': imgHover == index}]" v-for="(i, index) in coverList"
            :key="index">
            <img :src="i" class="detailleft-img-content" @mouseover="middleImg = i, imgHover = index">
          </div>
        </div>
        <div class="detail-middle">
          <img :src="middleImg" v-show="!rShow" alt class="middle-img" />
          <div class="maskTop" @mouseenter.stop="enterHandler" @mousemove.stop="moveHandler"
            @mouseout.stop="outHandler"></div>

          <div v-if="rShow" class="right-box">
            <div class="rightImg" :style="r_img">
              <img class="rightimage" :src="middleImg" alt="" />
            </div>
          </div>
        </div>

        <div calss="detail-right">
          <div class="detail-righttit">{{$i18n.locale == 'zh'?goodsData.goods_name:goodsData.en_goods_name}}</div>
          <div class="detail-rightext">
            {{$i18n.locale == "zh"?'类型':'type'}}：{{$i18n.locale == 'zh'?goodsData.category_name:goodsData.category_en_name}}
          </div>
          <div class="detail-rightext" style="font-size:20px">
            {{$i18n.locale == 'zh'?'产品简介':'product brief introduction'}}：</div>
          <div class="detail-rightbot"
            v-html="$i18n.locale == 'zh'?goodsData.short_description:goodsData.en_short_description"></div>
          <div class="rigrht-money">{{$i18n.locale == "zh"?'参考价格':'Reference price'}}：￥{{goodsData.price}}</div>
          <div class="color-picker">
            <template v-for="(item,index) in goodsData.product_images">
              <div class="icon" :key="index" :style="{display:item.icon?'flex':'none'}">
                <img :src="item.icon" alt="" @click="coverList=item.image_url;middleImg=item.image_url[0];">
                <div class="icon-text">{{$i18n.locale == 'zh'?item.color:item.en_color}}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="detail-s"></div>
      <div class="inform">{{$i18n.locale == "zh"?'产品信息':'Product information'}}</div>
      <div class="imgcommon" v-html="$i18n.locale == 'zh'?goodsData.goods_description:goodsData.en_goods_description">
      </div>
    </div>
  </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
export default {
  components: {
    pageFooter,
    pageHeader
  },
  data() {
    return {
      imgHover: 0,
      goodsData: '',
      middleImg: '',
      topStyle: { transform: "" },
      r_img: { transform: "" },
      topShow: false,
      rShow: false,
      coverList: [],
    }
  },
  methods: {
    // 鼠标进入原图空间函数
    enterHandler() {
      this.rShow = true;
    },
    // 鼠标移动函数
    moveHandler(event) {
      // 鼠标的坐标位置
      let topX = event.offsetX;
      let topY = event.offsetY;
      console.log(event)
      this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
    },
    // 鼠标移出函数
    outHandler() {
      // 控制层罩与放大空间的隐藏
      this.rShow = false;
    },
    getGoodsDetails() {
      let goodsId = this.$route.query.id
      this.$axios.get(`/goods/${goodsId}`)
        .then(res => {
          this.goodsData = res.data
          this.middleImg = res.data.covers[0]
          this.coverList = res.data.product_images[0].image_url
        })
    }
  },
  mounted() {
    this.getGoodsDetails()
  }
}
</script>
<style lang="scss" scoped>
.detail-top {
  width: 100%;
  padding: 45px 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #f6f6f6;
  position: relative;
}

.hard {
  width: 100%;
  padding-bottom: 60px;
  height: auto;
  margin: auto;
}

.detailleft {
  width: 116px;
}

.detailleft-img {
  width: 104px;
  height: 104px;
  margin-bottom: 9px;
  cursor: pointer;
  border: 1px solid #e5e5e5;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  &.hover {
    border: 1px solid #333;
  }
}
.detailleft-img-content {
  max-width: 104px;
  max-height: 104px;
}

.detail-middle {
  position: relative;
  margin: 0 30px;
  width: 500px;
  // height: 764px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.maskTop {
  width: 500px;
  height: 500px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
} /* 层罩，通过定位将左上角定位到(0,0) */
.right-box {
  // background: #FFF;
  // margin-left: 412px;
  width: 500px;
  height: 500px;
  border: 1px solid #ddd;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}
.rightImg {
  display: inline-block;
  width: 1500px;
  height: 1500px;
  // background-color: #1f1f1f;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .rightimage {
    max-width: 1500px;
    max-height: 1500px;
  }
} /* 右边的区域图片放大空间 */

.middle-img {
  width: 500px;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  // border: 1px solid #e5e5e5;
}

.detail-right {
  width: 560px;
  height: auto;
  margin-right: 150px;
}

.detail-righttit {
  // font-size: 18px;
  // font-family: Microsoft YaHei;
  // color: #1f1f1f;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #1f1f1f;
  line-height: 30px;
  font-size: 24px;
}

.detail-rightext {
  font-size: 18px;
  color: #1f1f1f;
  margin-top: 15px;
  margin-bottom: 15px;
}

.detail-rightbot {
  font-size: 18px;
  margin-left: 60px;
  font-family: Microsoft YaHei;
  color: #1f1f1f;
  line-height: 30px;
  white-space: pre-line;
  width: 500px;
}

.rigrht-money {
  margin-top: 70px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1f1f1f;
}

.detail-s {
  width: 100%;
  height: 1px;
  background: #e4e4e4;
  // margin-top: 60px;
  margin-bottom: 60px;
}

.inform {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1f1f1f;
  text-align: center;
  margin-bottom: 60px;
}

.imgcommon {
  // width: 1600px;
  margin: 0 auto 100px auto;
  &::v-deep * {
    max-width: 100%;
  }
}

.img-left {
  width: 450px;
  height: 900px;
}

.img-leftext {
  width: 450px;
  height: 900px;
}

.img-middle {
  width: 450px;
}

.middle-top {
  width: 450px;
  height: 500px;
}

.middle-bottom {
  width: 300px;
  height: 300px;
  margin-left: 50px;
  margin-top: 195px;
}

.word {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 100px;
}

.word-left {
  width: calc(50% - 31px);
  text-indent: 50px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1f1f1f;
  line-height: 30px;
  margin-bottom: 20px;
}

.color-picker{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.color-picker .icon {
  width: 50px;
  margin-right: 20px;
  margin-bottom: 20px;
  flex-direction: column;

}
.color-picker .icon img{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #e5e5e5;
}
.color-picker .icon .icon-text{
  color: '#000';
  text-align: center;
  font-size: 14px;
}

</style>
