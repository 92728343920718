<template>
	<div>
		<div class="app">
			<div class="total">
				<div class="sideright">

					<div class="tit">{{$i18n.locale == "zh"?details.name:details.en_name}}</div>
					<div class="smalltit" >{{$i18n.locale == 'zh'?category.name:category.en_name}} I {{details.activity_start_time || details.published_at}}</div>
					<div class="righttext" v-html="$i18n.locale == 'zh'?details.description:details.en_description"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				details: '',
				category: {}
			}
		},
		methods: {
			getDetails() {
				if(this.$route.query.dutyId) {
					let id = this.$route.query.dutyId
					this.$axios.get(`/duty/${id}`)
						.then(res => {
							this.details = res.data
							this.category = res.data.duty_category
						})
				} else {
					let id = this.$route.query.id
					this.$axios.get(`/activity/${id}`)
						.then(res => {
							this.details = res.data
							this.category = res.data.activity_category
						})
				}
				
			}
		},
		mounted() {
			this.getDetails()
		}
	}
</script>
<style scoped>
	.app {
		padding-top: 20px;
		width: 100%;
		margin: 0 auto;
		background: #fff 100%;
		padding: 0;
		margin: 0;
		background-repeat: no-repeat;
		padding-top: 20px;
		padding-bottom: 63px;
	}

	.total {
		width: 1280px;
		height: auto;
		margin: auto;
		display: flex;
		align-items: baseline;

	}

	.sideright {
		width: 100%;
		height: auto;
		background: #ffffff;
	}

	.return {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #1f1f1f;
		line-height: 30px;
		margin-left: 32px;
		margin-top: 20px;
	}

	.tit {
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		text-align: center;
		margin-top: 34px;
	}

	.smalltit {
		font-size: 17px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		text-align: center;
		margin-top: 19px;
	}

	.righttext {
		font-size: 18px;
		padding: 30px 0;
		margin-left: 110px;
		margin-right: 110px;
	}

	.tittext {
		font-size: 24px;
		line-height: 36px;
		color: #333333;
		margin-top: 63px;
		font-family: Microsoft YaHei;
		margin-bottom: 50px;
	}

	.old {
		line-height: 36px;
	}

	.olds {
		line-height: 36px;
		margin-top: 29px;
	}

	.frist {
		color: #333333;
		font-size: 16px;
		font-weight: bold;
		font-family: Microsoft YaHei;
	}

	.oldtext {
		font-size: 16px;
		color: #333333;
		font-family: Microsoft YaHei;
	}

	.img {
		width: 700px;
		height: 200px;

		margin: auto;
		margin-top: 58px;
		margin-bottom: 60px;
	}

	.activity-img {
		width: 100%;
		height: 100%;
	}

	.oldtexts {
		font-size: 16px;
		color: #333333;
		font-family: Microsoft YaHei;
		line-height: 36px;
	}
</style>
