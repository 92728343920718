import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './elementUI/element.js'
import './assets/js/flexible.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'gsQB6tCif69Kx4zNe4kWoQ94mKMpK16b'
})

import store from './store/index.js'
Vue.prototype.$store = store

import axios from 'axios'
let baseUrl = ''
Vue.prototype.$baseUrl = baseUrl

axios.interceptors.request.use((config) => {
	config.url = baseUrl + config.url
	let token = 'Bearer ' + localStorage.getItem('token');
	if(token) {
		config.headers.Authorization = token
	}
	return config
},
(err) => {
	console.log(err);
	return Promise.reject(err)
})

axios.interceptors.response.use((config) => {
	return config.data
},
(err) => {
	return Promise.reject(err)
})
Vue.prototype.$axios = axios


import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
	locale: localStorage.getItem('languageSet') || 'zh',
	messages: {
		'zh': require('./components/language/zh'),
		'en': require('./components/language/en')
	}
})

Vue.config.productionTip = false

new Vue({
	router,
	i18n,
    store,
	render: h => h(App)
}).$mount('#app')
