<template>
	<div>
		<div class="arttitle">
			<div class="titletext">{{$i18n.locale == "zh"?'艺术家＆形象大使':'Artist directory'}}</div>
			<img src="../assets/image/background.png" alt class="arttitimg" />
		</div>
		<div class="hard">
			<div class="gether">
				<!-- 图片 -->
				<div class="direleft">
					<img :src="artistDetails.artist_avatars" alt class="direleft-img" />
				</div>
				<!-- 文本 -->
				<div class="direright">
					<div class="direname">{{$i18n.locale == 'zh'?artistDetails.name :artistDetails.en_name || ''}}</div>
					<div class="viceName"  v-for="(i, index) in artistDetails.artist_ranks" :key="index">{{ $i18n.locale == 'zh'?i.rank_name:i.en_rank_name }}</div>
					
				</div>
			</div>
			<div class="direIdent" v-html="$i18n.locale == 'zh'?artistDetails.description:artistDetails.en_description"></div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				artistId: '',
				artistDetails: ''
			}
		},
		methods: {
			getDetails() {
				this.$axios.get(`/artist/${this.artistId}`)
					.then(res => {
						this.artistDetails = res.data
					})
			}
		},
		mounted() {
			this.artistId = this.$route.query.id
			this.getDetails()
		}
	};
</script>
<style lang="scss" scoped>
	.titletext {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 50;
		font-size: 28px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}

	.arttitle {
		width: 100%;
		height: 450px;
		position: relative;
	}

	.arttitimg {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.hard {
		width: 73%;
		height: auto;
		margin: auto;
	}

	.gether {
		display: flex;
		align-items: flex-start;
		margin: 50px 0;
	}

	.direleft {
		width: 210px;
		height: 270px;
		border: 1px solid;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
	}

	.direleft-img {
		/* width: 100%; */
		height: 100%;
	}

	.direright {
		width: 71%;
		height: auto;
		margin-left: 66px;
		padding-top: 28px;
	}

	.direname {
		/* font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333; */
		margin-bottom: 15px;

		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		color: #333333;
	}
	.viceName {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		color: #333333;
		line-height: 30px;
		/* margin-top: 3px;
		font-size: 12px;
		font-weight: bold;
		color: #555; */
	}
	.direIdent {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
		margin-top: 20px;
		padding: 20px 0;
		border-top: 1px solid #D6D6D6;
		& ::v-deep {
			font-family: Microsoft YaHei;
		}
	}

	.s {
		width: 100%;
		height: 1px;
		background: #d6d6d6;
		margin-top: 50px;
		margin-bottom: 50px;
	}

	.dirtext {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		line-height: 30px;
	}

	.dirtext-two {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		line-height: 30px;
		margin-top: 23px;
		margin-bottom: 65px;
	}
</style>
