<template>
  <div>
    <div class="block">
      <div class="imgtit">
        <!-- <img src="../assets/image/activitytwo.png" alt class="imgtits" /> -->
        <div class="banner-box">
    <el-carousel trigger="click" autoplay="true" height="450px" :interval="3000" arrow="always">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <!-- <div class="small" @click="$router.push(`/activity?dutyId=${item.id}`)"> -->
        <div class="small">
          <img :src="item.cover" alt class="swiperimg" />
        </div>
      </el-carousel-item>
    </el-carousel>

  </div>
      </div>
      <div class="swipernext">
        <div class="side-activity" v-if="list.length">
          <div class="activity" v-for="(i, index) of list"
            @click="$router.push(`/video?id=${i.id}&type=${$route.query.type}`)">
            <div class="time">{{i.video_duration}}</div>
            <div class="see">
              <img src="../assets/logo/see.png" alt class="seeicon" />{{i.clicks}}
            </div>
            <div class="img">
              <video width="99.9%" height="100%" class="video" >
                <source :src="i.img_url" type="video/mp4">
                </source>
                <source :src="i.img_url" type="video/ogg">
                </source>
                <source :src="i.img_url" type="video/webm">
                </source>
              </video>
            </div>
            <div class="bottomtext">{{$i18n.locale == 'zh'?i.name:i.en_name}}</div>
          </div>
        </div>
        <div v-else style="text-align:center;padding:50px 0">{{$i18n.locale == "zh"?'暂无内容':'No content'}}</div>
        <el-pagination background layout="prev, pager, next" hide-on-single-page="false" :total="list.length"
          calss="pagin"></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import sidebar from "../components/sidebar";
export default {
  components: {
    sidebar,
    pageFooter,
    pageHeader
  },
  data() {
    return {
      list: '',
      bannerList:[],
    }
  },
  methods: {
    getList() {
      this.list = []
      let url = `/official_video?type=${this.$route.query.cateType}`
      if (this.$route.query.type == 2) {
        url = `/product_video?cate_id=${this.$route.query.id}&type=${this.$route.query.cateType}`
      }

      this.$axios.get(url)
        .then(res => {
          this.list = res.data
        })
    },
    getBanner() {
      this.$axios.get('/banner-list')
        .then(res => {
          if(this.$route.query.type==1){
            this.bannerList = res.data['official_videos']
          }
          if(this.$route.query.type==2){
            this.bannerList = res.data['product_videos']
          }
        })
    },
  },
  watch: {
    $route(n, o) {
      this.getList()
      this.getBanner()
    }
  },
  mounted() {
    this.getList()
    this.getBanner()
  }
};
</script>
<style lang="scss" scoped>
.video {
  object-fit: cover;
}

.imgtit {
  width: 100%;
  // height: 450px;
}

.banner-box {
  position: relative;
  max-width: 1920px !important;
  margin: 0 auto;
  min-width: 1280px;
}

.imgtits {
  width: 100%;
  height: 100%;
  // object-fit: cover;
}

.swipernext {
  width: 1440px;
  height: 100%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-activity {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.activity {
  width: 450px;
  margin-bottom: 28px;
  position: relative;
  margin-right: 30px;
  cursor: pointer;

  &:nth-of-type(4n) {
    margin-right: 0;
  }
}

.time {
  position: absolute;
  left: 10px;
  bottom: 60px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  z-index: 50;
}

.see {
  position: absolute;
  right: 10px;
  bottom: 60px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fff;
  z-index: 50;
}

.seeicon {
  width: 23px;
  height: 17px;
  position: absolute;
  right: 35px;
  bottom: 0px;
}

.img {
  width: 100%;
  height: 264px;
  background: #333;
  cursor: pointer;
}

.imgs {
  width: 88px;
  height: 32px;
  background: #1f1f1f;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #ffffff;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomtext {
  height: 60px;
  width: 100%;
  background: #1f1f1f;
  padding: 0 15px;
  line-height: 60px;
  box-sizing: border-box;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img-list {
  font-size: 11px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.activimg {
  width: 100%;
  height: 100%;
}

.el-pagination {
}

.bottomun {
  margin-top: 60px;
}
.swiperimg{
            width: 100%;
            height: 450px;
            object-fit: cover;
          }
</style>
