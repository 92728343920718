<template>
	<div>
		<div class="hard">
			<!-- 左 -->
			<div class="gether">
				<div class="videoleft">
					<div class="leftimg" v-if="videoDetails.img_url">
						
						<video width="99.9%" height="100%" class="leftimgs" controls autoplay>
							<source :src="videoDetails.img_url" type="video/mp4"></source>
							<source :src="videoDetails.img_url" type="video/ogg"></source>
							<source :src="videoDetails.img_url" type="video/webm"></source>
							<object width="99.9%" height="100%" type="application/x-shockwave-flash" data="myvideo.swf">
								<param name="movie" :value="videoDetails.img_url" />
								<param name="flashvars" :value="`autostart=true&amp;file=${videoDetails.img_url}`" />
							</object>
							{{$i18n.locale == "zh"?'当前浏览器不支持 video直接播放，点击这里下载视频':'Current browser does not support video playback. Click here to download the video'}}: 
							<a href="myvideo.webm">{{$i18n.locale == "zh"?'下载视频':'Download video'}}</a>
						</video>
						
						<div class="playtit">{{$i18n.locale == 'zh'?videoDetails.name:videoDetails.en_name}}</div>
						<div class="play">
							<div class="times">{{$i18n.locale == "zh"?'播放':'play'}}：{{videoDetails.clicks}}</div>
							<div class="times">{{$i18n.locale == "zh"?'上传时间':'Upload time'}}：{{videoDetails.published_at}}</div>
						</div>

						<div class="playtext" v-html="$i18n.locale == 'zh'?videoDetails.description:videoDetails.en_description"></div>
					</div>
				</div>
				<!-- 右 -->
				<div>
					<div class="side-activity">
						<div class="activity" v-for="(i, index) of list" v-if="index < 5">
							<div class="time">{{i.video_duration}}</div>
							<div class="see">
								<img src="../assets/logo/see.png" alt class="seeicon" />{{i.clicks}}
							</div>
							<div class="img" @click="getVideo(i.id)">
								<video width="99.9%" height="100%" class="video" >
									<source :src="i.img_url" type="video/mp4"></source>
									<source :src="i.img_url" type="video/ogg"></source>
									<source :src="i.img_url" type="video/webm"></source>
								</video>
							</div>
							<div class="bottomtext" @click="getVideo(i.id)">
								<div class="img-bottom">{{i.name}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				videoDetails: {},
				list: ''
			}
		},
		methods: {
			getVideo(id) {
				this.videoDetails = {}
				let url = `/official_video/${id||this.$route.query.id}`
				if(this.$route.query.type == 2) {
					url = `/product_video/${id||this.$route.query.id}`
				}
				
				this.$axios.get(url)
					.then(res => {
						this.videoDetails = res.data
						console.log(this.videoDetails) 
					})
			},
			getList() {
				this.list = []
				let url = '/official_video'
				if(this.$route.query.type == 2) {
					url = '/product_video'
				}
				
				this.$axios.get(url)
					.then(res => {
						this.list = res.data
					})
			}
		},
		mounted() {
			this.getVideo()
			this.getList()
		}
	};
</script>
<style lang="scss" scoped>
	.video {
		object-fit: cover;
	}
	
	.hard {
		width: 1280px;
		margin: auto;
		height: auto;
	}

	.gether {
		display: flex;
		align-items: flex-start;
		margin-top: 20px;
	}

	/* 左 */
	.videoleft {
		width: 83%;
		margin-bottom: 52px;
	}

	.leftimgs {
		width: 100%;
		height: 600px;
		background: #000;
		/* object-fit: cover; */
	}

	.play {
		margin-right: 30px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #6e6e6e;
		// margin-left: 6px;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
	}

	.times {
		margin-right: 20px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
	}

	.playtit {
		clear: both;
		font-size: 24px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin: 18px 0 13px 0;
	}

	.playtext {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
		text-indent: 20px;
	}

	/* 右 */
	.side-activity {
		width: 300px;
		padding: 15px 15px 5px 15px;
		margin-left: 20px;
		background: #ededed;
	}

	.activity {
		margin-bottom: 10px;
		position: relative;
	}

	.time {
		position: absolute;
		left: 10px;
		bottom: 60px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #fff;
		z-index: 50;
	}

	.see {
		position: absolute;
		right: 10px;
		bottom: 60px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #fff;
		z-index: 50;
	}

	.seeicon {
		width: 22px;
		height: 17px;
		position: absolute;
		right: 35px;
		bottom: 0px;
	}

	.img {
		width: 100%;
		height: 160px;
		background: #333;
	}

	.imgs {
		width: 88px;
		height: 32px;
		background: #1f1f1f;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		color: #ffffff;
		line-height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.bottomtext {
		width: 100%;
		border: 1px solid;
		background: #1f1f1f;
		padding: 10px;
		box-sizing: border-box;
	}

	.img-bottom {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffffff;
		margin-bottom: 7px;
	}

	.img-list {
		font-size: 11px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffffff;
	}

	.activimg {
		width: 100%;
		height: 100%;
	}

	.el-pagination {
		position: absolute;
		left: 400px;
	}

	.bottomun {
		margin-top: 60px;
	}
</style>
