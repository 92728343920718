<template>
    <div>
        <!-- <pageHeader></pageHeader> -->
        <div class="app">
            <div class="total" v-if="detail">
                <sidebar class="sidebar"></sidebar>
                <div class="sideright">
                    <div class="return">
                        <span @click="back">{{$i18n.locale == "zh"?'返回':'back'}}</span>
                    </div>
                    <div class="tit">{{ $i18n.locale == "zh"?detail.name:detail.en_name }}</div>
                    <div
                        class="smalltit"
                    >{{ $i18n.locale == "zh"?detail.activity_category.name :detail.activity_category.en_name}} I {{ detail.created_at }}</div>
                    <div class="righttext">
                        <div class="righttext-html" v-html="$i18n.locale == 'zh'?detail.description:detail.en_description"></div>
                        <div class="time">
                            <div class="times">
                                <div>{{$i18n.locale == "zh"?'活动时间':'The activity time'}}：{{ detail.activity_start_time }}至{{ detail.activity_end_time }}</div>
                                <div>{{$i18n.locale == "zh"?'报名人数':'The registration number'}}：{{ detail.activity_nums }}人</div>
                                <div>{{$i18n.locale == "zh"?'报名条件：不限':'Application requirements: No limit'}}</div>
                                <div>{{$i18n.locale == "zh"?'积分':'integral'}}：{{ detail.integral }}</div>
                                <div>{{$i18n.locale == "zh"?'状态':'state'}}：{{ $i18n.locale == 'zh'?activityStatus:en_activityStatus }}</div>
                            </div>
                        </div>
                        <div class="but" v-if="activityStatus != '已结束'&&detail.is_join == 0" @click="add">{{$i18n.locale == "zh"?'报名参加':'Sign up for'}}</div>
                        <div class="but" v-else-if="detail.is_join != 0" style="background-color:#ccc">{{$i18n.locale == "zh"?'已报名':'Have to sign up'}}</div>
                        <div class="but" v-else style="background-color:#ccc">{{$i18n.locale == "zh"?'报名结束':'End of registration'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <pageFooter></pageFooter> -->
    </div>
</template>
<script>
import pageFooter from "../components/pageFooter.vue";
import pageHeader from "../components/pageHeader.vue";
import sidebar from "../components/sidebar";
export default {
    components: { sidebar, pageFooter, pageHeader },
    data() {
        return {
            activity_category_id: '',
            detail: null,
            activityStatus: '已发起',
            en_activityStatus: 'launched',
        }
    },
    mounted() {
        this.activity_category_id = this.$route.query.activity_category_id
        this.getData()
    },
    methods: {
        getData() {
            this.$axios.get('/activity/' + this.activity_category_id).then(res => {
                this.detail = res.data
                let nowTime = new Date().getTime()
                let startTime = new Date(this.detail.activity_start_time).getTime()
                let endTime = new Date(this.detail.activity_end_time).getTime()
                if (startTime < nowTime && nowTime < endTime) {
                    this.activityStatus = '进行中'
                    this.en_activityStatus = 'underway'
                } else if (startTime > nowTime) {
                    this.activityStatus = '已发起'
                    this.en_activityStatus = 'launched'
                } else {
                    this.activityStatus = '已结束'
                    this.en_activityStatus = 'finished'
                }
            })
        },
        add(){
            this.$axios.post('/add_activity_to_user',{
                activity_id: this.detail.id
            }).then(res=>{
                if (res.code == 200) {
                    this.$message.success(res.message);
                    this.getData()
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        back() {
            history.go(-1)
        }
    },
};
</script>
<style scoped>
.app {
    padding-top: 20px;
    width: 100%;
    margin: 0 auto;
    background: #f1f1f1 100%;
    padding: 0;
    margin: 0;
    background-repeat: no-repeat;
    padding-top: 20px;
    padding-bottom: 63px;
}

.total {
    width: 1280px;
    height: auto;
    margin: auto;
    display: flex;
    align-items: baseline;
}

.sideright {
    margin-left: 25px;
    width: calc(100% - 265px);
    height: auto;
    background: #ffffff;
}
.return {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 30px;
    margin-left: 32px;
    margin-top: 20px;
}
.tit {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-top: 34px;
}
.smalltit {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    text-align: center;
    margin: 19px 0;
}
.righttext {
    margin-left: 110px;
    margin-right: 110px;
}
.righttext-html >>> img {
    max-width: 100%;
}
    
.tittext {
    font-size: 16px;
    line-height: 36px;
    color: #333333;
    margin-top: 63px;
    font-family: Microsoft YaHei;
    margin-bottom: 50px;
}
.old {
    line-height: 36px;
}
.olds {
    line-height: 36px;
    margin-top: 29px;
}
.frist {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    font-family: Microsoft YaHei;
}
.oldtext {
    font-size: 16px;
    color: #333333;
    font-family: Microsoft YaHei;
}
.img {
    width: 700px;
    height: 200px;
    border: 1px solid;
    margin: auto;
    margin-top: 58px;
    margin-bottom: 60px;
}
.oldtexts {
    font-size: 16px;
    color: #333333;
    font-family: Microsoft YaHei;
    line-height: 36px;
}
.time {
    width: 100%;
    height: auto;
    background: #f1f1f1;
    padding: 28px 0px 28px 30px;
    margin-top: 40px;
}
.times {
    font-size: 16px;
    color: #333333;
    font-family: Microsoft YaHei;
    line-height: 36px;
}
.but {
    width: 180px;
    height: 44px;
    background: #1f1f1f;
    /* border: 1px solid #1f1f1f; */
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 61px;
    margin-bottom: 61px;
}
</style>