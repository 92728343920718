module.exports = {
	language: {
		name: 'English'
	},
	header: {
		AboutUs: '关于我们',
		CompanyProfile: '产品服务',
		AudioVisualMaterials: '影音资料',
		SocialResponsibility: '新闻资讯',
		login: '登录',
		register: '注册'
	},
	home: {
		artist: '艺术家'
	}
}
