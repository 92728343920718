<template>
	<div>
		<div class="arttitle">
			<div class="titletext">{{$i18n.locale == "zh"?'售后服务':'After-sales service'}}</div>
			<div class="titletexts">{{$i18n.locale == "zh"?'（售后服务须知）请您仔细阅读本须知。并填写乐器购买信息。激活售后保障服务':'(After-sales service Notice) Please read this notice carefully.And fill in the instrument purchase information.Activate after-sales support service'}}</div>
		</div>
		<div class="banner-box">
        <el-carousel
          trigger="click"
          autoplay="true"
          height="450px"
          :interval="3000"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <div class="small" @click="$router.push(`/activity?dutyId=${item.id}`)"> -->
            <div class="small">
              <img :src="item.cover" alt class="swiperimg" />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
		<div class="hard" v-if="$route.query.id==1">
			<div class="put">
				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'乐器类型':'instrument type'}}：</div>
					<el-cascader active-text-color="#000" v-model="sendData.musical_instrument_type_name" :options="options" :placeholder="$i18n.locale == 'zh'?'请选择乐器型号':'please select'" class="select-gender "
						 :props="$i18n.locale == 'zh'?optionProps:en_optionProps" @change="changeType" ref="musical_instrument_type"></el-cascader>
				</div>

				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'乐器型号':'Instrument model'}}：</div>
					<!-- <input v-model="sendData.musical_instrument_model" type="text" placeholder="请输入乐器型号"
						class="product-put" /> -->
					<el-select v-model="sendData.musical_instrument_model" active-text-color="#000"  :placeholder="$i18n.locale == 'zh'?'请选择乐器型号':'please select'" class="select-gender ">
						<el-option
						v-for="item in categoryModels"
						:key="item.index"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</div>

				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'制造编号':'Manufacturing number'}}：</div>
					<input v-model="sendData.manufacturing_code" type="text" :placeholder="$i18n.locale == 'zh'?'请输入制造编号':'please input'"
						class="product-put" />
				</div>

				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'您的姓名':'your name'}}：</div>
					<input v-model="sendData.name" type="text" :placeholder="$i18n.locale == 'zh'?'请输入您的姓名':'please input'" class="product-put" />
				</div>

				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'联系电话':'Contact phone'}}：</div>
					<input v-model="sendData.mobile_number" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" type="number" :placeholder="$i18n.locale == 'zh'?'请输入联系电话':'please input'" class="product-put" />
				</div>

				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'收货地址':'Shipping address'}}：</div>
					<input v-model="sendData.receiving_address" type="text" :placeholder="$i18n.locale == 'zh'?'请输入您的常用地址':'please input'"
						class="product-put" />
				</div>

				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'使用年限':'service time'}}：</div>
					<input v-model="sendData.service_years" type="text" :placeholder="$i18n.locale == 'zh'?'请输入使用年限':'please input'" class="product-put" />
				</div>

				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'首选维修地址':'Preferred repair address'}}：</div>
					<input v-model="sendData.current_address" type="text" :placeholder="$i18n.locale == 'zh'?'请输入地址':'please input'" class=" product-put" />
				</div>

				<!-- <div class="li">
					<div class="label">首选维修地址：</div>
					<input type="text" placeholder="请输入您的常用地址"
						class="product-put" />
				</div> -->

				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'问题描述：':'Problem Description:'}}</div>
					<textarea v-model="sendData.problem_description" class="productext"
						maxlength="200"	:placeholder="$i18n.locale == 'zh'?'请输入问题描述':'Please enter a problem description'"></textarea>
				</div>

				<div class="li">
					<div class="label">{{$i18n.locale == "zh"?'上传图片：':'Upload pictures:'}}</div>
					<div class="right-content">
						<el-upload :action="$baseUrl + '/api/upload_after_sale'" list-type="picture-card" name="file"
							:limit="3" :on-success="upload" :on-exceed="exceed" class="upload-file">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>
			</div>


			<div class="elbottom" @click="getLogIn">{{$i18n.locale == "zh"?'提交':'submit'}}</div>
		</div>
		<div class="html" v=-else v-html="htmlContent">123</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [],
				optionProps: {
					value: 'name',
					label: 'name',
					children: 'children_categories',
					expandTrigger: 'hover'
				},
				en_optionProps: {
					value: 'en_name',
					label: 'en_name',
					children: 'children_categories',
					expandTrigger: 'hover'
				},
				fileList: [],
				sendData: {
					musical_instrument_type_name: '',
					musical_instrument_model: '',
					manufacturing_code: '',
					name: '',
					mobile_number: '',
					receiving_address: '',
					service_years: '',
					current_address: '',
					problem_description: '',
					repair_image: '',
					type: 1
				},
				typeData: [],
				categoryModels: [],
				token: localStorage.getItem('token'),
				htmlContent:'',
				bannerList: [],
			}
		},
		// created() {
		// 	console.log(this.token)
		// 	if(!this.token) {
		// 		this.$message(this.$i18n.locale == 'zh'?'登录使用更多功能':'Log in to use more features')
		// 		this.$router.push("/sign");
		// 	}
		// },
		watch:{
            $route:{
                handler(nv,ov){
					this.getTypeList()
					this.getHtml()
                }
            },
            deep:true
        },
		methods: {
			changeType() {
				let id = this.$refs.musical_instrument_type.getCheckedNodes()[0].data.id
				console.log(id)
				this.$axios.get('/category_models?category_id=' + id)
					.then(res => {
						this.categoryModels = res.data
					})
			},
			getLogIn() {
				console.log(this.token)
				if(!this.token) {
					this.$confirm(this.$i18n.locale == 'zh'?'登录使用更多功能':'Log in to use more features')
						.then(_ => {
							this.$router.push("/sign");
						}).catch(_ => {});
				} else {
					this.submit()
				}
			},
			submit() {
				let url = ''
				console.log(this.fileList);
				for(let i of this.fileList) {
					url += `,${i}`
				}
				this.sendData.repair_image = url.substr(1)
				if(!(this.sendData.musical_instrument_type_name&&
					this.sendData.musical_instrument_model&&
					this.sendData.manufacturing_code&&
					this.sendData.name&&
					this.sendData.mobile_number&&
					this.sendData.receiving_address&&
					this.sendData.service_years&&
					this.sendData.current_address&&
					this.sendData.problem_description&&
					this.sendData.repair_image)) {
						this.$message.error(this.$i18n.locale == 'zh'?'请检查输入内容':'Check the input');
						return
					}
					console.log(this.sendData.mobile_number.length )
				if(this.sendData.mobile_number.length != 11) {
					this.$message.error(this.$i18n.locale == 'zh'?'请输入11位电话':'Please enter an 11-digit phone number');
					return
				}
				let value = ''
				for(let v of this.sendData.musical_instrument_type_name) {
					value = value + " " + v
				}
				this.sendData.musical_instrument_type_name = value
				
				this.$axios.post('/add_user_after_sale', this.sendData)
					.then(res => {
						console.log(res)
						this.$message.success(this.$i18n.locale == 'zh'?'售后申请已提交':'After sale application has been submitted');
						
						this.sendData = {
							musical_instrument_type_name: '',
							musical_instrument_model: '',
							manufacturing_code: '',
							name: '',
							mobile_number: '',
							receiving_address: '',
							service_years: '',
							current_address: '',
							problem_description: '',
							repair_image: ''
						}
						window.location.reload()
						this.categoryModels = []
					}, error => {
						console.log(error)
						// this.$message.error(error.message)
					})
			},
			upload(res) {
				if(res.code == 200) {
					this.$message.success(res.message);
					this.fileList.push(res.data.url)
				} else {
					this.$message.error(res.message.file[0]);
				}
			},
			exceed() {
				this.$message.error({
					message: this.$i18n.locale == 'zh'?'超出最大上传数量':'The maximum number of uploads exceeded'
				});
			},
			getTypeList() {
				this.$axios.get('/categories')
					.then(res => {
						this.typeData = res.data
						let temp=[];
						// 根据网址传过来的id查询对应的分类
						for(let i of this.typeData){
							if(i.id == this.$route.query.id){
								temp=i.children_categories
							}
						}
						this.options = this.getTreeData(temp)
					})
			},
			getTreeData(data) {
				// 循环遍历json数据
				for (var i = 0; i < data.length; i++) {

					if (data[i].children_categories.length < 1) {
						// children若为空数组，则将children设为undefined
						data[i].children_categories = undefined;
					} else {
						// children若不为空数组，则继续 递归调用 本方法
						this.getTreeData(data[i].children_categories);
					}
				}
				return data;
			},
			getHtml(){
				this.$axios.get('/get_user_after_sale?type='+this.$route.query.id).then(res=>{
					console.log(res)
					this.htmlContent=res.data.content
				})
			},
			getBanner() {
        this.$axios.get("/banner-list").then((res) => {
          this.bannerList = res.data["distributors"];
        });
      },
		},
		mounted() {
			this.getHtml()
			this.getTypeList()
			this.getBanner()
		}
	}
</script>

<style lang="scss" scoped>
	@import url("../style/eltwo.css");

	input[type='number'] {
		-moz-appearance:textfield;
	}
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.select-gender {
		& ::placeholder {
			color: #777;
		}
	}
	::v-deep .el-input__inner,.product-put,.product-putname,.productext {
		color: #222!important;
		border-color: #6a6a6a!important;
		outline-color: #aaa;
		&::placeholder {
			color: #999;
		}
	}
	.upload-file {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	::v-deep .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
		color: #000;
	}
	.li {
		width: 100%;
		display: flex;
		align-items: center;

		.label {
			font-size: 16px;
			margin-top: 32px;
			margin-right: 10px;
			flex: 1;
			height: 56px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}

	.right-content {
		margin-top: 32px;
		width: 772px;
	}

	.titletexts {
		width: 100%;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 300;
		color: #ffffff;
		line-height: 30px;
		text-align: center;
	}

	.titletext {
		width: 100%;
		font-size: 28px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
		text-align: center;
		margin-bottom: 30px;
	}

	.arttitle {
		width: 100%;
		height: 450px;
		// background-image: url("../assets/image/background.png");
		// background-position: center center;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		position: relative;
		z-index: 2;
	}

	.hard {
		width: 1280px;
		margin: 0 auto;
	}

	.put {
		width: 1000px;
		height: auto;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #1f1f1f;
		text-align: center;
		margin: auto;
	}

	.product-put {
		width: 760px;
		height: 48px;
		background: #ffffff;
		border: 1px solid #6a6a6a;
		border-radius: 4px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		line-height: 30px;
		padding-left: 10px;
		margin-top: 32px;
		outline: none;
	}

	.put1 {
		margin-left: 30px;
	}

	.elbottom {
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 180px;
		height: 44px;
		border: 1px solid #6a6a6a;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #1f1f1f;
		margin-top: 60px;
		margin-bottom: 60px;
		cursor: pointer;
	}

	.first {
		margin-right: 32px;
	}

	.productext {
		width: 760px;
		height: 120px;
		background: #ffffff;
		border: 1px solid #6a6a6a;
		border-radius: 4px;
		margin-top: 30px;
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		line-height: 30px;
		padding: 10px 0 10px 10px;
		outline: none;
	}

	.question {
		position: relative;
	}

	.quest {}

	.html {
		width: 1280px;
		padding: 20px 0;
		margin: 0 auto;
	}

	
	.banner-box {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
      height: 450px;
	  margin-top: -450px;
	  z-index: 1;
	  min-width: 1280px;
        .swipertext {
          width: 1280px;
          height: 100%;
          margin: 0 auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 20;
          display: flex;
          align-items: center;
        }
      
        .swipertexts {
        }
        .small {
          height: 100%;
          width: 100%;
          .swiperimg{
            width: 100%;
            height: 450px;
            object-fit: cover;
          }
        }
      }
</style>
