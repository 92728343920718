<template>
  <div class="background">
    <img src="../assets/image/background.png" class="background-img" />
    <div class="hard">
      <div class="hardimg-div">
        <img src="../assets/logo/title2.png" class="hard-img" />
        <div class="hard-text">
          <div class="hard-tit">
            <div class="text-tit active1" v-for="(item,index) of List" :key="index" @click="check(index)">
              {{item.a}}
              <div class="end-line" :style="{'opacity': isActive1 == index ? 1 : 0}"></div>
            </div>
          </div>
          <!-- 账号登录 -->
          <div class="phone" v-show="isActive1 == 0">
            <div class="phone-s">
              <div class="label">+86</div>
              <!-- <i class="el-icon-arrow-down"></i> -->
              <input type="text" :placeholder="$i18n.locale == 'zh'?'请输入手机号':'Please enter your mobile phone'"
                class="inp" v-model="mobile" />
            </div>
            <div class="phone-s">
              <div class="label">{{$i18n.locale == "zh"?'密码':'password'}}</div>
              <input type="password" :placeholder="$i18n.locale == 'zh'?'请输入密码':'Please enter your password'"
                class="inp" v-model="password" />
            </div>
            <div class="phone-but">
              <div class="but-left" @click="toforgetpassword">{{$i18n.locale == "zh"?'忘记密码':'Forgot password'}}</div>
              <div class="but-right" @click="toRegister">
                {{$i18n.locale == "zh"?'还没账号？快速注册':"Don't have an account yet?Quick registration"}}</div>
            </div>
            <div class="login-but" @click="clickLogin">{{$i18n.locale == "zh"?'登录':' login'}}</div>
          </div>

          <!-- 验证码登录 -->
          <div class="phone" v-show="isActive1 == 1">
            <div class="phone-s">
              <div class="label">+86</div>
              <!-- <i class="el-icon-arrow-down"></i> -->
              <input type="text" :placeholder="$i18n.locale == 'zh'?'请输入手机号':'Please enter your mobile phone'"
                class="inp" v-model="mobile" />
            </div>
            <div class="phone-s">
              <div class="label">{{$i18n.locale == "zh"?'验证码':'verification code'}}</div>
              <input type="password" :placeholder="$i18n.locale == 'zh'?'请输入验证码':'Please enter code'" class="inp"
                v-model="code" />
              <div class="but" @click="uplogin" v-if="codeStatus == 1">{{$i18n.locale == "zh"?'获取验证码':'obtaining'}}
              </div>
              <div class="but" @click="uplogin" v-if="codeStatus == 2">{{codeTime}}s</div>
            </div>
            <div class="phone-but">
              <div class="but-left" @click="toforgetpassword">{{$i18n.locale == "zh"?'忘记密码':'Forgot password'}}</div>
              <div class="but-right" @click="toRegister">
                {{$i18n.locale == "zh"?'还没账号？快速注册':"Don't have an account yet?Quick registration"}}</div>
            </div>
            <div class="login-but" @click="clickLogintwo">{{$i18n.locale == "zh"?'登录':'login'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="power">
      {{$i18n.locale == "zh"?'版权所有©2021伊斯曼音乐公司隐私政策条款与条件':'Eastman music Co, LTD all rights reserved ©2021'}}</div>
  </div>
</template>
<script>
import {
  mapMutations
} from "vuex";
import api from "../api/index.js";
export default {
  data() {
    return {
      photw: false,
      pho: false,

      List: [{
        a: '账号登录',
        b: 1
      }, {
        a: '验证码登录',
        b: 2
      }],
      isActive1: 0,
      show: true,
      // deletes: true,
      mobile: "",
      password: "",
      usage: "",
      code: "",
      codeStatus: 1,
      codeTime: 60,
      codeTimeout: ''
    };
  },
  methods: {
    check(index) {
      this.isActive1 = index;
    },
    // 密码登录
    clickLogin() {
      this.$axios.post('/oauth/token', {
        mobile: this.mobile,
        password: this.password
      }).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message);
          localStorage.setItem("token", res.data.access_token);
          setTimeout(() => {
            this.$router.replace('/Home');
          }, 1500);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取验证码
    uplogin() {
      if (this.codeStatus == 1) {
        this.$axios.post('/oauth/send_code', {
          mobile: this.mobile,
          usage: "login"
        }).then(res => {
          console.log(res);
          if (res.code == 422) {
            this.$message.error(res.message);
          } else if (res.code == 200) {
            this.$message.success('验证码已发送');
            this.codeStatus = 2
            this.codeTime = 60
            this.codeTimeout = setInterval(() => {
              this.codeTime--
              if (this.codeTime == 0) {
                this.codeStatus = 1
                this.codeTime = 60
                clearInterval(this.codeTimeout)
              }
            }, 1000)
          }
        });
      } else {
        this.$message.error(`请在${this.codeTime}后重试`);
      }
    },
    // 验证码登录
    clickLogintwo() {
      this.$axios.post('/oauth/mobile_login', {
        mobile: this.mobile,
        code: this.code
      })
        .then(res => {
          if (res.code == 200) {
            localStorage.setItem("token", res.data.access_token);
            this.$router.push({
              path: '/Home'
            })
          }
        });
    },
    toRegister() {
      this.$router.push({
        path: "/register"
      });
    },
    toforgetpassword() {
      this.$router.push({
        path: "/forgetpassword"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.background {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.background-img {
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  position: absolute;
}

.hard {
  width: 1280px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
}

.hardimg-div {
  margin-left: 850px;
  width: 400px;
}

.hard-img {
  width: 225px;
  height: 38px;
  color: #ffffff;
  display: block;
  margin: auto;
  margin-bottom: 60px;
}

.hard-text {
  width: 400px;
  height: 408px;
  background: #ffffff;
  border-radius: 10px;
}

.hard-tit {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.text-tit {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  margin-top: 50px;
  margin-bottom: 40px;
  cursor: pointer;
}

.end-line {
  width: 46px;
  height: 3px;
  background: #1f1f1f;
  margin: auto;
  margin-top: 5px;
  transition: 0.3s all;
}

.active1 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333;
  line-height: 30px;
  transition: 0.5s all;
}

.manyphone {
  margin-left: 1000px;
}

.phone {
  margin-left: 33px;
  margin-right: 33px;
}

.phone-s {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  border-bottom: 1px solid #e4e4e4;
  padding: 10px 0;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.label {
  width: 60px;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.inp {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
  margin-left: 20px;
}

.phone-but {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.but-left {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999;
  text-align: center;
  cursor: pointer;
  transition: 0.3s all;
}

.but-left:hover {
  color: #333;
}

.but-right {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999;
  cursor: pointer;
  transition: 0.3s all;
}

.but-right:hover {
  color: #333;
}

.login-but {
  width: 310px;
  height: 48px;
  background: #1f1f1f;
  border-radius: 24px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fffcfc;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 40px;
  cursor: pointer;
}

.but {
  background: #1f1f1f;
  flex: 1;
  padding: 0 10px;
  border-radius: 10px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.power {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  left: 50%;
  bottom: 39px;
  transform: translate(-50%);
}
</style>
