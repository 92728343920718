import Vue from 'vue';
import {
    Button,
    Carousel,
    CarouselItem,
    Collapse,
    CollapseItem
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)